import Vue from 'vue';
import App from './App.vue';
import Metrika from './Metrika';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueTheMask from "vue-the-mask";
import Vuelidate from "vuelidate";
import VueCookies from "vue-cookies";
import METRIKA_CONSTS from '@/store/constants/metrika';

// Filters
import dateFilter from '@/filters/date';
import dateServerFilter from '@/filters/date.server.filter';
import roleFilter from '@/filters/role.filter';
import sectionTypeFilter from '@/filters/finance/section.types.filter';
import subscribeFilter from '@/filters/subscribe.filter';

//Vue.config.devtools = true;
Vue.filter('dateServerFilter', dateServerFilter);
Vue.filter('dateFilter', dateFilter);
Vue.filter('subscribeFilter', subscribeFilter);
Vue.filter('sectionTypeFilter', sectionTypeFilter);

Vue.filter('roleFilter', roleFilter);
Vue.config.productionTip = false;
Vue.prototype.BASE_URL = 'http://localhost:8081';

Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(VueCookies);

Object.defineProperty(Vue.prototype, "$bus", {
	get() {
		return this.$root.bus;
	},
});

Object.defineProperty(Vue.prototype, "$metrika", {
	get() {
		const $store = this.$root.$store;
		const result = this.$root.metrika;
		result.consts = METRIKA_CONSTS;

		result.add = function(id, payload = {}, forYandex) {
			this.addYandex(id, forYandex || payload);
			return $store.dispatch('metrika/addStats', { id, payload });
		};

		return result;
	},
});

new Vue({
	data: ctx => ({
		metrika: new Metrika({ ctx }),
		bus: new Vue({}),
	}),
  router,
  store,
  render: h => h(App)
}).$mount('#app');
