import _ from 'lodash';
import CONSTS from '@/store/constants/metrika';

const store = { namespaced: true };

store.getters = {
  logParams(state, getters, rootState, rootGetters) {
    return {
      service: 'muzerudit',
      message: 'Metrika',
      app_ver: '1.0.0',
      payload: {
        game: getters.currentGamePayload,
        user: {
          id: rootState.user._id,
          role: rootState.user.role,
          category: rootState.user.user_category,
        },
      },
    };
  },

  currentGamePayload: (state, getters, rootState, rootGetters) => ({
    usedHelpName: !!rootGetters.soundOfHistory?.showName,
    completedSounds: rootState.game.completedSounds,
    trackId: rootState.game.currentSoundId,
    stockId: rootState.stock.selectedStockId,
    difficulty: rootState.game.currentDifficulty,
    words: getters.currentWordsPayload,
  }),

  currentWordsPayload(state, getters, rootState, rootGetters) {
    const length = _.size(rootGetters.currentSoundWords);
    const usedHelps = rootGetters.currentSoundWordTipsCount;
    const answered = _.size(_.filter(rootGetters.currentSoundAnswerWords, 'valid'));
    const percent = Number((answered / length * 100).toFixed(2));
    return { length, usedHelps, answered, percent };
  },
};


store.actions = {
  addStats({ getters, rootState }, { id: metrikaId, payload = {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!metrikaId) return reject(new Error('metrikaId not found'));
        if (typeof metrikaId === 'object') metrikaId = metrikaId.key;
        await new Promise(r => setTimeout(r, 100));

        const params = _.cloneDeep(getters.logParams);
        if (!rootState.token)
          delete params.payload.user;
        if (!rootState.token || !rootState.game.token)
          delete params.payload.game;
        params.payload = _.defaultsDeep(payload, params.payload);

        const options = _.get(CONSTS, metrikaId, {});
        params.payload.eventName = options.id;
  
        console.log(`log [${metrikaId}]:`, _.cloneDeep(params));
        params.payload = JSON.stringify(params.payload);
        await rootState.app.sdk.log.info(params);
        resolve(true);
      } catch(err) {
        reject(err);
      };
    });
  },
};

export default store;