export default {
  computed: {
    modalExecutorAdd: ths => ({
title: "Добавить исполнителя", notification: 'Исполнитель успешно добавлен', id: 'executor-add', request: 'api/executor', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
        },
        inputs: [
          {name: 'Исполнитель *', placeholder: 'Исполнитель песни', id: 'name', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalExecutorEdit: ths => ({
title: "Редактировать исполнителя", notification: 'Исполнитель успешно отредактирован', id: 'executor-edit', request: 'api/executor', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
        },
        inputs: [
          {name: 'Исполнитель *', placeholder: 'Исполнитель песни', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
        ]
    }),

    modalExecutorRemove: ths => ({
title: "Удалить исполнителя", notification: 'Исполнитель успешно удален', id: 'executor-remove', request: 'api/executor', message: 'этого исполнителя', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};