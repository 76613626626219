export default {
  computed: {
    modalNewsAdd: ths => (
        {
            title: "Добавить новость",
            notification: 'Новость успешно добавлена',
            id: 'newsfeed-add',
            request: 'api/newsfeed',
            method: 'post',
            emit: 'createNews',
            file: null,
            file_request: null,
            variables: {
                title: '',
                content: '',
                user_category: [],
            },
            inputs: [
                {name: 'Заголовок *', placeholder: 'Заголовок новости ', id: 'title', grid: '1 / 7', value: '', drop: false},
                {name: 'Текст', placeholder: 'Текст новости', id: 'content',  grid: '1 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: false, context: ''},
                {name: 'Категория', placeholder: 'Для каких игроков новость', id: 'user_category', grid: '1 / 4', value: '', drop: true, showBoxDrop: false, dropBoxs: true, context: '' , drop_data: [{ _id: 'megafon', name: 'megafon' }, { _id: 'defualt', name: 'для обычных' },  { _id: 'all', name: 'для всех' }]},
            ]
    }),
      modalNewsEdit: ths => ({
          title: "Редактировать новость",
          notification: 'Новость успешно отредактирована',
          id: 'newsfeed-edit',
          request: 'api/newsfeed',
          method: 'put',
          emit: 'editNews',
          file: null,
          file_request: null,
          imageUpload: false,
          variables: {
              _id :  ths.modal.element._id,
              content:ths.modal.element.content,
              title:ths.modal.element.title,
              user_category: ths.modal.element.user_category && ths.modal.element.user_category.length > 1 ? "Для всех" : ths.modal.element.user_category ? ths.modal.element.user_category[0] : "",
              status_id: ths.modal.element.status_id
          },
          inputs:
          [
              {name: 'Заголовок *', placeholder: 'Заголовок новости ', id: 'title', grid: '1 / 7', value: '', drop: false},
              {name: 'Текст', placeholder: 'Текст новости', id: 'content',  grid: '1 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: false, context: ''},
              {name: 'Категория', placeholder: 'Для каких игроков новость', id: 'user_category', grid: '1 / 4', value: '', drop: true, showBoxDrop: false, dropBoxs: true, context: '' , drop_data: [{ _id: 'megafon', name: 'megafon' }, { _id: 'defualt', name: 'для обычных' },  { _id: 'all', name: 'для всех' }]},
              {name: 'Статус *', placeholder: 'Статус', id: 'status_id', grid: '4 / 7', value: '', drop: true, showDrop: false, context: '', drop_data: [{ _id: 'Активна', name: 'Активна' }, { _id: 'Неактивна', name: 'Неактивна' }]},
          ]
      }),
      modalNewRemove: ths => ({
          title: "Удалить новость",  notification: 'Новость успешно удалена', id: 'newsfeed-remove', request: 'api/newsfeed', message: 'этоту новость', method: 'delete', emit: 'deleteNews', file: null, file_request: null,
          variables: {
              _id: ths.modal.element._id,
          },
      }),
  },
};