export default {
  computed: {
    modalFilterAdd: ths => ({
title: "Добавить сайт-фильтр", notification: 'Сайт-фильтр успешно добавлен', id: 'filter-add', request: 'api/filter', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
          description: '',
        },
        inputs: [
          {name: 'Сайт-фильтр *', placeholder: 'Сайт-фильтр', id: 'name', grid: '1 / 7', value: '', drop: false},
          {name: 'Комментарий *', placeholder: 'Комментарий', id: 'description', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalFilterEdit: ths => ({
title: "Редактировать сайт-фильтр", notification: 'Сайт-фильтр успешно отредактирован', id: 'filter-edit', request: 'api/filter', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
          description: ths.modal.element.description,
        },
        inputs: [
          {name: 'Сайт-фильтр *', placeholder: 'Сайт-фильтр', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
          {name: 'Комментарий *', placeholder: 'Комментарий', id: 'description', grid: '1 / 7', value: ths.modal.element.description, drop: false},
        ]
    }),

    modalFilterRemove: ths => ({
title: "Удалить сайт-фильтр", notification: 'Сайт-фильтр успешно удален', id: 'filter-remove', request: 'api/filter', message: 'этот сайт-фильтр', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};