<template>
  <main v-if="user">
    <Sidebar />
    <router-view />
  </main>
</template>

<script>
import Sidebar from '@/components/sidebar/Sidebar';

export default {
  name: "main-layout",
  data() {
    return {
      user: null
    }
  },
  components: {
    Sidebar
  },
  watch: {
    '$store.state.user': function() {
      if (this.$store.state.user) {
        if (this.$store.state.user.role === 3) {
          return this.$router.push('/');
        }
        this.user = this.$store.getters.user;
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('checkToken');
    this.$store.dispatch('getUserData');
  }
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  height: 100%;
  min-width: 1280px;
  display: flex;
  background-color: #f4f5f9;
}
</style>