import _ from 'lodash';
import axios from 'axios';
import promiseWrapper from './promiseWrapper.js';

export default function(context, from, to, path = 'data') {
  return promiseWrapper(async () => {
    const response = await axios.get(from, context.rootGetters.axiosOptions);
    context.commit(to, _.get(response, path));
    return _.get(response, path);
  });
};