import _ from 'lodash';
import * as APP_CONSTS from '@/constants/app.js';
// import METRIKA_CONSTS from '@/constants/metrika';

export default class Metrika {
  constructor({ ctx }) {
    this.ctx = ctx;
    this.history = {};
    this.consts = {};
    this.config = this.getExtendedConsts(this.consts);
  }

  setUserParams(userParams = {}) {
    if (!userParams || userParams.toString() !== '[object Object]') return;
    _.invoke(window, 'ym', APP_CONSTS.METRIKA.ID, 'userParams', userParams);
  }

  addYandex(metrikaId, payload = {}) {
    if (!metrikaId) return console.error('metrikaId not found');
    if (typeof metrikaId === 'object') metrikaId = metrikaId.id;
    let { limit = Infinity, history = 0, type = 'reachGoal', description } = _.get(this.config, metrikaId, {});
    if (typeof metrikaId === 'function') metrikaId = metrikaId(payload);
    if (typeof description === 'function') description = description(payload);
    const consoleText = `[${ description || 'METRIKA' }]:`;
    const log = i => console[i](consoleText, type, metrikaId, `${history}/${limit}`);
    if (++history > limit) return log('err');
    _.invoke(window, 'ym', APP_CONSTS.METRIKA.ID, type, metrikaId.toString());
    log('warn');
  }

  getExtendedConsts(consts) {
    return Object.entries(consts).reduce((acc, [key, value]) => {
      if (!value.id) return acc;
      acc[value.id] = { ...value, key };
      return acc;
    }, {});
  }
};