export const GAME_START = {
  description: 'Игра началась',
  id: 'game-start',
};

export const GAME_BACKWARD = {
  description: 'Смена карточки на предыдущую',
  id: 'game-change-backward-sound',
};

export const GAME_FORWARD = {
  description: 'Смена карточки на следующую',
  id: 'game-change-forward-sound',
};

export const GAME_LEVEL_UP = {
  description: 'Повышение уровня сложности',
  id: 'game-change-level-up',
};

export const GAME_LEVEL_DOWN = {
  description: 'Понижение уровня сложности',
  id: 'game-change-level-down',
};

export const GAME_SOUND_TIMECOST = {
  description: 'Как долго пользователь задержался на одной карточке',
  id: time => `game-sound-linger-${time}`,
};

export const GAME_SELECT_WORD = {
  description: type => `${type} ~ Выбрано слово на поле`,
  id: type => `game-select-word-${type}`,
};

export const GAME_SOUND_FINISH = {
  description: 'Введены верно все слова из карточки',
  id: 'game-sound-finish',
};

export const GAME_AUTHOR_FINISH = {
  description: 'Пользовать выбрал одного из авторов',
  id: 'game-author-finish',
};

export const GAME_USE_HELP_LAMP = {
  description: `Использование подсказки-лампы`,
  id: count => `game-use-help-lamp-${count}`, 
};

export const GAME_USE_HELP_EYE = {
  description: 'Использование подсказки-глаза',
  id: `game-use-help-eye`,
};

export const GAME_FINISH = {
  description: 'Карточка отгадана',
  id: 'game-finish',
};

export const GAME_CONTINUE = {
  description: 'Игра началась заново',
  id: 'game-continue',
};