<template>
  <section id="app-container">
    <div class="game">
      <router-view />
    </div>
  </section>
</template>


<script>
export default {
  name: 'GameAuthLayout',

  async mounted() {
    if (!this.$router.currentRoute.query.payload) 
      await this.$store.dispatch('checkGameToken');
  },
};
</script>


<style lang="scss" scoped>
$font-family: 'Montserrat', sans-serif;

#app-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  overflow: auto;
  background-color: rgb(240, 249, 255);

  .game {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    overflow: auto;
    background-color: #fff;
    background-image: url(../assets/bg.svg);
    background-position: 100%;
    background-size: cover;
    width: 100%;
    height: 100%;
    font-family: $font-family;
    color: #FFFFFF;
    max-width: 600px;
  }
}
</style>