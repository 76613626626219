export default {
  computed: {
    modalAuthorAdd: ths => ({
      title: "Добавить автора", notification: 'Автор успешно добавлен', id: 'author-add', request: 'api/author', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
        },
        inputs: [
          {name: 'Автор *', placeholder: 'Автор текста и музыки', id: 'name', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalAuthorEdit: ths => ({
title: "Редактировать автора", notification: 'Автор успешно отредактирован', id: 'author-edit', request: 'api/author', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
        },
        inputs: [
          {name: 'Автор *', placeholder: 'Автор текста и музыки', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
        ]
    }),

    modalAuthorRemove: ths => ({
title: "Удалить автора", notification: 'Автор успешно удален', id: 'author-remove', request: 'api/author', message: 'этого автора', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};