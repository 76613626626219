<template>
  <div class="notification" :class="notification.status">
    <div>{{ notification.message }}</div>
  </div>
</template>

<script>
export default {
  name: "CommonNotification",
  props: ["notification"],
};
</script>

<style lang="scss" scoped>
.notification {
  z-index: 1000;
  animation: notification 3s ease;
  animation-fill-mode: forwards;
  position: fixed;
  top: -3%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid;
  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  &.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  &.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  &.danger-game {
    color: #fff;
    background-color: #ff2135;
    border: none;
  }
  &.warning-game {
    color: #fff;
    background-color: #e4c769;
    border: none;
  }
  &.success-game {
    color: #fff;
    background-color: #3cc45b;
    border: none;
  }
}
	
@keyframes notification { 
  0% {
    top: -3%;
  }
  100% {
    top: 5%;
  }
}
</style>