<template>
  <div id="app">
    <Preloader v-if="!layout || lodash.isNull(isRepairWorks) || !settingsLoaded"/>
    <RepairLayout 
      v-else-if="isRepairWorks && layout.startsWith('game')"
      :text="repairLayourText"
    />
    <template v-else>
      <component :is="layout">
        <router-view />
      </component>
      <GameInstructionVideo
        ref="instruction"
        :video="instructionVideo"
        @exit="$bus.$emit('exitInstuction')"
      />
      <transition name="fade">
        <CommonModal 
          v-if="onShowModal" 
          @showNotification="showNotification" 
          @removeModal="removeModal"
        />
      </transition>
      <transition name="fade">
        <CommonNotification 
          v-if="onShowNotification" 
          :notification="notification"
        />
      </transition>
    </template>
  </div>
</template>


<script>
import _ from 'lodash';
import Preloader from '@/components/game/Preloader';
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import GameLayout from "@/layouts/GameLayout";
import RepairLayout from "@/layouts/RepairLayout";
import GameAuthLayout from "@/layouts/GameAuthLayout";
import CommonModal from "@/components/common/CommonModal";
import CommonNotification from "@/components/common/CommonNotification";
import GameInstructionVideo from '@/components/game/GameInstructionVideo';

import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: "App",

  components: {
    Preloader,
    MainLayout,
    AuthLayout,
    GameLayout,
    RepairLayout,
    GameAuthLayout,
    CommonModal,
    CommonNotification,
    GameInstructionVideo,
  },

  data: () => ({
    lodash: _,
    onShowModal: false,
    onShowNotification: false,
    block: false,
    notification: null,
    timeout: null,
  }),

  watch: {
    modal() {
      if (!this.modal) return;
      this.onShowModal = true;
    },
    'user._id': {
      immediate: true,
      handler(id) {
        if (!id) return;
        if (this.selectedStockId) return;
        this.getLastEndedStock();
        this.getActiveStock();
      },
    },
    'user.type': 'setMetrikaParams',
    'user.login': 'setMetrikaParams',
    'user.user_category': {
      immediate: true,
      handler: 'setMetrikaParams',
    },
  },

  computed: {
    ...mapGetters([
      'user', 
      'modal', 
      'instructionVideo',
      'settingsLoaded',
      'selectedStockId',
      'isRepairWorks',
      'repairLayourText',
    ]),
    layout: ths => (ths.$route.meta.layout || 'auth') + '-layout',
  },

  async mounted() {
    this.getAppSettings();
    this.$bus.$on('showNotification', (data) => {
      this.showNotification(data)
    });
    this.getSDK();
    await this.getInstructionVideo();
    this.$bus.$on('showInstuction', isShowCloseButton => {
      _.invoke(this.$refs.instruction, 'showModal', isShowCloseButton);
    });
  },

  methods: {
    ...mapMutations(['registerSDK']),
    ...mapActions([
      'getInstructionVideo',
      'getActiveStock',
      'getLastEndedStock',
      'getAppSettings',
    ]),

    async getSDK() {
      if (!window.___advmInit) return setTimeout(this.getSDK.bind(this), 200);
      const sdk = await window.___advmInit();
      this.registerSDK(sdk);
    },

    showNotification(notification) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.notification = notification;
      this.onShowNotification = true;
      this.timeout = setTimeout(() => {
        this.notification = null;
        this.onShowNotification = false;
      }, 5500);
    },

    removeModal() {
      this.onShowModal = false;
    },

    setMetrikaParams() {
      if (!this.user) return;
      const { user_category, type, login } = this.user;
      if (!user_category || !type || !login) return;
      const isGuest = login === 'guest';
      this.$metrika.setUserParams({ user_category, type, isGuest });
    },
  },
};
</script>


<style lang="scss">
@import url("../public/css/common/reset.min.css");
@import url("../public/css/common/datepicker.min.css");
@import url("../public/css/common/general.css");
@import url("../public/css/common/fonts.min.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700&display=swap');

* {
  box-sizing: border-box;
}

.swiper-container, 
.swiper-wrapper, 
.swiper-slide {
  height: 100% !important;
  max-width: inherit;
}

.swiper-container-horizontal {
  .swiper-wrapper {
    display: grid !important;
    grid-auto-flow: column !important;
  }
}

.swiper-container-vertical {
  .swiper-wrapper {
    display: block !important;
    grid-auto-flow: column !important;
  }
}

.page-top .swiper-slide {
  overflow-x: hidden;
  max-height: calc(100vh - 165px);
}

a {
  text-decoration: none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="file"] {
  display: none;
}

#app {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #fff
}

.btn.pending {
  opacity: 0.5;
}
.pending {
  pointer-events: none;
}

.general {
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 50, 92, 0.15);
  border-radius: 12px;
  flex-grow: 1;
}

.block {
  margin: 30px 20px;
}

.paginate_block {
  display: flex;
  flex-direction: column;
}

.btn {
  background-color: #6886F8;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 500;
  padding: 10px 25px;
  font-family: "Montserrat", sans-serif;
}
.btn-auth {
  font-weight: 600;
  text-transform: none;
}
.btn-gradient {
  background: linear-gradient(82.84deg, #6886F8 17.53%, #D885FF 90.75%), #AD7AFF;
}
.btn-add {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}
.btn-div {
  font-size: 13px;
  height: 36px;
  display: flex;
  align-items: center;
}
.btn-white {
  background-color: #fff;
  color: #00325C;
}

.input__block {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  min-width: 0;
  span {
    color: #636468;
    font-size: 11px;
    margin-bottom: 5px;
  }
  &.disabled {
    input {
      color: #9e9e9e !important;
      border-color: #9e9e9e !important;
      &::-webkit-input-placeholder {
        color: #9e9e9e !important;
      }
    }
    svg {
      path {
        stroke: #9e9e9e !important;
      }
    }
  }
  input, textarea {
    color: #00325C;
    width: 100%;
    padding: 15px;
    border: none;
    outline: none;
    font-weight: 500;
    border-radius: 5px;
    transition: 0.3s all;
    &.error {
      box-shadow: 0 0 0px 1px red;
      background-color: #ffe5e5;
      transition: 0.3s all;
    }
  }
  .input {
    color: #00325C;
    // width: 230.95px;
    padding: 15px;
    height: 46px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    transition: 0.3s all;
    background-color: #F8F8FB;
    .content {
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  > textarea {
    resize: none;
  }
  svg {
    // bottom: 18px;
    // right: 14px;
    position: absolute;
    right: 10px;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding: 5px;
    height: 20px;
    background: #fff;
    cursor: pointer;
    top: calc(50% - 10px);
    border-radius: 5px;
    stroke: #9E9DBB;
    &:hover:not([disabled]) {
      background-color: #D885FF;
      stroke: #6461F6;
    }
  }
  svg.active {
    transition: 0.3s all;
    transform: rotate(180deg);
  }
  .drop {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 1px 1px #cccccc;
    width: 100%;
    top: 100%;
    z-index: 1;
    border-radius: 0 0 5px 5px;
    ul {
      max-height: 125px;
      overflow: auto;
      li {
        font-size: 12px;
        color: #292929;
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 1px solid #f1f1f1;
        }
        span {
          margin-bottom: 0;
          margin-left: 10px;
        }
        .search {
          width: 100%;
          padding: 5px 10px;
          font-size: 12px;
        }
        .checkbox {
          border: 1px solid #ebebeb;
          &::before {
            top: 1px;
            left: 5px;
            width: 4px;
            height: 9px;
            border-color: #9e9e9e;
          }
        }
      }
    }
  }
  .drop-game {
    border: 2px solid white;
    box-shadow: none;
    border-radius: 0 0 10px 10px;
    top: 97%;
    ul {
      li {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        padding: 10px 18px;
        background-color: #403653;
        &:not(:last-child) {
          border-bottom: 1px solid #3B304F;
        }
      }
    }
  }
}

.preloader {
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  color: #00325C;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.opacity_0 {
  opacity: 0 !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #6886F8;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
