export const APP_INSTRUCTION_START = {
  description: 'Пользователь начал смотреть видео-инструкцию',
  id: 'app-instruction-start',
};

export const APP_INSTRUCTION_FIRST_START = {
  description: 'Пользователь впервые начал смотреть видео-инструкцию',
  id: 'app-instruction-first-start',
};

export const APP_INSTRUCTION_SKIP = {
  description: 'Пользователь пропустил видео-инструкцию нажав на фон',
  id: 'app-instruction-skip',
};

export const APP_INSTRUCTION_CLOSE = {
  description: 'Пользователь пропустил видео-инструкцию нажав на крестик',
  id: 'app-instruction-close',
};

export const APP_INSTRUCTION_COMPLETE = {
  description: 'Пользователь посмотрел видео-инструкцию полностью',
  id: 'app-instruction-complete',
};

export const APP_INSTRUCTION_ERROR = {
  description: 'Видео-инструкция сломалась',
  id: 'app-instruction-error',
};

export const APP_MASTERSCRIPT_LOAD_ERROR = {
  description: 'Ошибка загрузки мастер-скрипта',
  id: 'app-masterscript-load-error',
};