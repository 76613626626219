export default function(fn) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await fn(resolve, reject);
      resolve(result);
    } catch (err) {
      console.error(err);
      reject(err);
    };
  });
};