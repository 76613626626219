export default {
	computed: {
    modalStockWinnersAdd: ths => ({

    }),

    modalStockWinnersEdit: ths => ({

    }),

    modalStockWinnersRemove: ths => ({

    }),
	},
};