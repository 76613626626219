import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

const store = {};

store.state = () => ({
  timerLimit: 10,
});

store.actions = {
  showADS({ rootGetters, dispatch }) {
    return new Promise(async resolve => {
      if (_.get(rootGetters, 'user.type') === 'Премиум') return resolve();
      dispatch('addEventListeners', resolve);
      dispatch('addYandexAd');
    });
  },

  addEventListeners({ state }, resolve) {
    const skipButtonEl = document.getElementById('skip_button');
    const secondsEl = document.getElementById('seconds');
    const adsEl = document.querySelector('.ads');

    function onSkip() {
      adsEl.classList.add('d_none');
      adsEl.classList.remove('flex');
      skipButtonEl.classList.add('d_none');
      secondsEl.innerText = state.timerLimit;
      skipButtonEl.removeEventListener('click', onSkip);
      resolve();
    };

    let seconds = state.timerLimit;
    let interval = setInterval(() => {
      secondsEl.innerText = --seconds;
      if (seconds) return;
      clearInterval(interval);
      skipButtonEl.classList.remove('d_none');
      skipButtonEl.addEventListener('click', onSkip);
    }, 1000);

    if (adsEl.classList.contains('d_none')) {
      adsEl.classList.remove('d_none');
      adsEl.classList.add('flex');
    } else {
      adsEl.classList.remove('flex');
      adsEl.classList.add('d_none');
    };
  },

  addYandexAd() {
    (function (w, d, n, s, t) {
      w[n] = [];
      w[n].push(function () {
        Ya.Context.AdvManager.render({
          blockId: "R-A-735601-1",
          renderTo: "yandex_rtb_R-A-735601-1",
          async: true
        });
      });
      t = d.getElementsByTagName("script")[0];
      s = d.createElement("script");
      s.type = "text/javascript";
      s.src = "//an.yandex.ru/system/context.js";
      s.async = true;
      t.parentNode.insertBefore(s, t);
    })(window, window.document, "yandexContextAsyncCallbacks");
  },
};

export default store;