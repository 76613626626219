const constants = {};
constants.api = {};

constants.api.game = {};
constants.api.game.LIBRARY = '/api/library/list';
constants.api.game.SETTINGS = '/api/game/settings';
constants.api.game.SCORES = '/api/scores';
constants.api.game.SPEEDS = '/api/speeds';

constants.api.game.SOUNDS = '/api/game/sounds';
constants.api.game.SOUNDS_DEMO = '/api/sounds/demo';
constants.api.game.SOUND = '/api/gamer/sound';

constants.api.game.USE_WORD_TIP = '/api/game/word/tip';
constants.api.game.USE_SOUND_TIP = '/api/game/sound/tip';
constants.api.game.CHECK_WORD = '/api/game/word/valid';
constants.api.game.CHECK_WORDS = '/api/game/words/valid';
constants.api.game.GET_AUTHORS = '/api/game/authors';
constants.api.game.SOUND_FINISH = '/api/game/finish';

export default constants;