export default {
  computed: {
    modalStatsGet: ths => ({
      title: "Выгрузка статистики", 
      notification: 'Статистика успешно скачана', 
      id: 'music-stats-get', 
      request: 'api/excel/stats', 
      responseType: 'blob',
      filename: 'stats.xlsx',
      method: 'get', 
      emit: '', 
      variables: {
        start_date: '',
        end_date: '',
      },
      inputs: [{
        name: 'Дата начала *', 
        placeholder: 'гггг.мм.дд', 
        id: 'start_date', 
        grid: '1 / 7', 
        value: '', 
        // drop: true, 
        onlyDrop: true,
        showDrop: false, 
        mask: '####.##.##',
      }, {
        name: 'Дата окончания *', 
        placeholder: 'гггг.мм.дд', 
        id: 'end_date', 
        grid: '1 / 7', 
        value: '', 
        // drop: true, 
        onlyDrop: true,
        showDrop: false, 
        mask: '####.##.##',
      }],
    }),
  },
};