export default {
  computed: {
    modalLibraryAdd: ths => ({
        title: "Добавить песню",  notification: 'Песня успешно добавлена', id: 'library-add', request: 'api/library', method: 'post', emit: 'createLibrary', file: null, file_request: null, imageUpload: false,
        variables: {
          name: '',
          executor: [],
          status_id: '',
          order: '',
          duration: '',
          type: '',
        },
        inputs: [
          {name: 'Название *', placeholder: 'Название песни', id: 'name', grid: '1 / 4', value: '', drop: false},
          {name: 'Исполнитель *', placeholder: 'Исполнитель песни', id: 'executor', grid: '4 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Порядок *', placeholder: 'Порядок проигрывание', id: 'order', grid: '1 / 4', value: '', mask: '####'},
          {name: 'Статус *', placeholder: 'Статус', id: 'status_id', grid: '4 / 7', value: '', drop: true, showDrop: false, multiple: true,  context: '', drop_data: [{ _id: 'Активна', name: 'Активна' }, { _id: 'Неактивна', name: 'Неактивна' }]},
        ]
    }),

    modalLibraryEdit: ths => ({
        title: "Редактировать песню",  notification: 'Песня успешно отредактирована', id: 'library-edit', request: 'api/library', method: 'put', emit: 'editLibrary', file: null, file_request: null, imageUpload: false,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
          executor: ths.modal.element.executor_id,
          executor_id: ths.modal.element.executor_id?.map(el => el._id),
          status_id: ths.modal.element.status_id,
          order: ths.modal.element.order,
          duration: ths.modal.element.duration,
          type: ths.modal.element.type,
        },
        inputs: [
          {name: 'Название *', placeholder: 'Название песни', id: 'name', grid: '1 / 4', value: '', drop: false},
          {name: 'Исполнитель *', placeholder: 'Исполнитель песни', id: 'executor', grid: '4 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Порядок *', placeholder: 'Порядок проигрывание', id: 'order', grid: '1 / 4', value: '', mask: '####'},
          {name: 'Статус *', placeholder: 'Статус', id: 'status_id', grid: '4 / 7', value: '', drop: true, showDrop: false, context: '', drop_data: [{ _id: 'Активна', name: 'Активна' }, { _id: 'Неактивна', name: 'Неактивна' }]},
        ]
    }),

    modalLibraryRemove: ths => ({
title: "Удалить песню",  notification: 'Песня успешно удалена', id: 'library-remove', request: 'api/library', message: 'этоту песню', method: 'delete', emit: 'removeLibrary', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};