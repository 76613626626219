import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

const store = {};

store.state = () => ({
  stocks: {},
  selectedStockId: null,
  lastEndedStockId: null,
});

store.getters = {
  stocksList: state => state.stocks,
  selectedStockId: state => state.selectedStockId,
  selectedStock: ({}, { stocksList, selectedStockId }) => _.get(stocksList, selectedStockId, {}),
  lastEndedStock: ({ lastEndedStockId }, { stocksList }) => _.get(stocksList, lastEndedStockId, {}),
  prizesBySelectedStock: ({}, { selectedStock }, { prize }) => _.pick(prize.prizes, _.keys(selectedStock.prizes)),
  prizesLenght: ({}, { prizesBySelectedStock }) => _.size(prizesBySelectedStock),
};

store.actions = {
  getActiveStock({ dispatch, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const findOptions = { status: 'active' };
        if (['staging', 'development'].includes(process.env.NODE_ENV)) {
          const stockId = (new URL(window.location)).searchParams.get('stockId');
          if (stockId) {
            findOptions.status = ['active', 'testing', 'ended'];
            findOptions._id = [stockId];
          };
        };
        const stocks = await dispatch('getStocks', {
          ignoreExisting: true,
          findOptions,
          limit: 1,
        });
        commit('setSelectedStockId', _.keys(stocks)[0]);
        resolve(stocks);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  getLastEndedStock({ dispatch, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const findOptions = { status: 'ended' };
        const sort = { end_date: -1 };
        const stocks = await dispatch('getStocks', {
          ignoreExisting: true,
          filter: { sort },
          findOptions,
          limit: 1,
        });
        commit('setLastEndedStockId', _.keys(stocks)[0]);
        resolve(stocks);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  getStocks({ rootState, commit, dispatch }, { filter, findOptions, ids, ignoreExisting = false, limit = 30, params = {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = { headers: {}, params };
        options.headers.token = rootState.token;
        options.params.limit = limit;
        options.params.filter = filter;
        options.params.findOptions = findOptions;
        options.params.ids = ignoreExisting ? _.values(_.omit(ids, _.keys(rootState.stocks))) : ids;
        const response = await axios.get('/api/stocks', options);
        const data = _.get(response, 'data.data', response.data);

        const promises = data.map(stock => {
          return new Promise(async resolve => {
            const ignoreExisting = true;
            const prizesIds = _.keys(stock.prizes);
            const membersIds = _.keys(stock.members);
            const filter = { sort: { [`stocks.${stock._id}.score`]: -1 } };
            const findUsers = { [`stocks.${stock._id}`]: { $exists: true } };
            const findWinners = {};
            if (rootState.isAdminPage) {
              Object.assign(findWinners, { 
                [`stocks.${stock._id}.prize`]: { $in: prizesIds },
              });
            };
            const prizesPromise = dispatch('getPrizes', { ignoreExisting, ids: prizesIds });
            const membersPromise = dispatch('getUsers', { ignoreExisting, ids: membersIds, find: findUsers, filter, limit: 30 });
            await Promise.all([ prizesPromise, membersPromise ]);
            await dispatch('getUsers', { ignoreExisting, ids: membersIds, find: findWinners, limit: 30 });
            commit('addStock', stock);
            resolve(stock);
          });
        });

        const stocks = await Promise.all(promises);
        resolve(stocks.reduce((acc, stock) => ({ ...acc, [stock._id]: stock }), {}));
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
};

store.mutations = {
  addStocks(state, newStocks) {
    Vue.set(state, 'stocks', {
      ...state.stocks,
      ...newStocks,
    });
  },
  addStock(state, newStock) {
    if (!newStock._id) return;
    Vue.set(state.stocks, newStock._id, newStock);
  },
  editStock(state, newStock = {}) {
    const oldStock = _.get(state.stocks, newStock._id, {});
    const updatedStock = _.defaultsDeep({}, newStock, oldStock);
    oldStock && Vue.set(state.stocks, newStock._id, updatedStock);
  },
  removeStock(state, { stockId }) {
    if (!stockId) return;
    Vue.delete(state.stocks, stockId);
  },

  setSelectedStockId(state, newStockId) {
    Vue.set(state, 'selectedStockId', newStockId);
  },
  setLastEndedStockId(state, newStockId) {
    Vue.set(state, 'lastEndedStockId', newStockId);
  },
  createPrizeFromStock(state, { stockId, prizeId, prize }) {
    const stock = state.stocks[stockId];
    stock && Vue.set(stock.prizes, prizeId, prize);
  },
  editPrizeFromStock(state, { stockId, prizeId, prize }) {
    const stock = state.stocks[stockId];
    const oldPrize = _.get(stock.prizes, prizeId, {});
    const updatedPrize = _.defaultsDeep({}, prize, oldPrize);
    stock && Vue.set(stock.prizes, prizeId, updatedPrize);
  },
  removePrizeFromStock(state, { stockId, prizeId }) {
    const stock = state.stocks[stockId];
    stock && Vue.delete(stock.prizes, prizeId);
  },
};

export default store;
