import Vue from 'vue';
import Vuex from 'vuex';

import tokenAction from './tokenAction';
import analyticsModule from './modules/analytics';
import metrikaModule from './modules/metrika';
import stockModule from './modules/stock';
import prizeModule from './modules/prize';
import userModule from './modules/user';
import gameModule from './modules/game';
import appModule from './modules/app';
import adModule from './modules/ad';

import persistedState from 'vuex-persistedstate';
import axios from 'axios';
import DateFilter from '@/filters/date.filter';
import router from '@/router/index';

Vue.use(Vuex);

import * as APP_CONSTS from '@/constants/app';

const store = {};
store.state = () => ({
  token: null,
  launch: null,
  startGameTime: null,
  modal: null,
  user: null,
  settingsGame: {},
  promotionСhannel: {},
  orientation: 'portrait-primary',
  hasHotNews: false,
});


store.getters = {
  token: state => state.token,
  launch: state => state.launch,
  hasHotNews: state => state.hasHotNews,
  startGameTime: state => state.startGameTime,
  modal: state => state.modal,
  user: state => state.user,
  isMegafonWithSubscriptionUser: state => state.user.user_category == 'megafonSubscription',
  isMegafonWithoutSubscriptionUser: state => state.user.user_category == 'megafon',
  isDefaultUser: state=> state.user.user_category == 'default',
  settingsGame: state => state.settingsGame,
  orientation: state => state.orientation,
  isAdminPage: () => window.location.href.startsWith(window.location.origin + '/admin'),
  storageUrl: (state, getters, rootState) => rootState.app.storageUrl,
};


store.actions = {
  setHotNewsStatus: ({ commit }, status) => commit('setHotNewsStatus', status),
  createGameDuration: ({ commit }) => commit('setGameDuration', new Date()),
  updateUserCategory: ({ commit }, category) => commit('setUserCategory', category),
  createModal: ({ commit }, modal) => commit('openModal', modal),
  getUserStaticData: ({ commit }, user) => commit('getUser', user),
  getOrientation: ({ commit }, orientation) => commit('setOrientation', orientation),
  addSettingsGame: ({ commit }, options) => commit('setSettingsGame', options),
  createLaunch: ({ commit }) => commit('setLaunch'),

  async checkGameDuration({ commit, getters }) {
    try {
      if (!getters.startGameTime) return;
      const headers = { token: getters.token };
      const date = DateFilter(new Date(), 'datehour', true, true);
      const duration = Math.ceil((new Date() - getters.startGameTime) / 1000);
      await axios.put('/api/analytics/gameDuration', { duration, date }, { headers });
      commit('clearGameDuration');
    } catch (err) {
      console.log(err);
    };
  },

  async updateSubscription({ getters, dispatch }) {
    try {
      const headers = { token: getters.token };
      const response = await axios('/api/user/update-subscription', { headers });
      if (response.data === getters.user.user_category) return;
      dispatch('getUserData');
    } catch (err) {
      console.log(err);
    };
  },

  async getUserData({ commit, getters, dispatch, rootState }) {
    try {
      if ([4].includes(rootState.user.role)) return;
      const headers = { token: getters.token };
      const response = await axios('/api/auth/me', { headers });
      commit('getUser', response.data);
    } catch (err) {
      console.log(err);
      if (err.response.status !== 404) return;
      dispatch('logout');
    };
  },

  loginByToken({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/api/auth/login/token', payload);
        dispatch('createToken', response.data.token);
        commit('getUser', response.data);
        resolve(response.data);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
};


store.mutations = {
  setOrientation: (state, orientation) => state.orientation = orientation,
  openModal: (state, modal) => state.modal = modal,
  setHotNewsStatus: (state, status) => state.hasHotNews = status,
  setSettingsGame: (state, options) => state.settingsGame = options,

  setLaunch: state => state.launch = new Date(),
  clearLaunch: state => state.launch = null,
  setGameDuration: (state, time) => state.startGameTime = time,
  clearGameDuration: state => state.startGameTime = null,

  setUserCategory: (state, category) => state.user.user_category = category,
  getUser: (state, user) => state.user = user,
  setToken: (state, token) => state.token = token,
  clearToken(state) {
    state.token = null;
    localStorage.setItem('vuex', null);
  },
};


store.modules = {
  tokenAction, 
  analytics: analyticsModule,
  metrika: metrikaModule,
  stock: stockModule,
  prize: prizeModule,
  user: userModule,
  game: gameModule,
  app: appModule,
  ad: adModule,
};


store.plugins = [
  persistedState({
    paths: ['token', 'launch', 'startGameTime'],
  }),
];


export default new Vuex.Store(store);
