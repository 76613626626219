export default {
  computed: {
    modalSpeedAdd: ths => ({
      title: "Добавить время открытия", 
      notification: 'Время открытия успешно добавлено', 
      id: 'speed-add', 
      request: 'api/speed', 
      method: 'post', 
      emit: 'createSetting', 
      file: null, 
      file_request: null,
      variables: {
        min_time: '',
        max_time: '',
        coefficient: ''
      },
      inputs: [{
        name: 'От *', 
        placeholder: '000', 
        id: 'min_time', 
        grid: '1 / 4', 
        value: '', 
        drop: false, 
        mask: '###',
      }, {
        name: 'До *', 
        placeholder: '000', 
        id: 'max_time', 
        grid: '4 / 7', 
        value: '', 
        drop: false, 
        mask: '###',
      },{
        name: 'Коэффициент скорости *', 
        placeholder: '000', 
        id: 'coefficient', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }],
    }),

    modalSpeedEdit: ths => ({
      title: "Редактировать время открытия", 
      notification: 'Время открытия успешно отредактировано', 
      id: 'speed-edit', 
      request: 'api/speed',
      method: 'put', 
      emit: 'editSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element?._id,
        min_time: ths.modal.element?.min_time,
        max_time: ths.modal.element?.max_time,
        coefficient: ths.modal.element?.coefficient,
      },
      inputs: [{
        name: 'От *', 
        placeholder: '000', 
        id: 'min_time', 
        grid: '1 / 4', 
        value: ths.modal.element?.min_time, 
        drop: false, 
        mask: '###',
      },{
        name: 'До *', 
        placeholder: '000', 
        id: 'max_time', 
        grid: '4 / 7', 
        value: ths.modal.element?.max_time, 
        drop: false, 
        mask: '###',
      },{
        name: 'Коэффициент скорости *', 
        placeholder: '000', 
        id: 'coefficient', 
        grid: '1 / 7', 
        value: ths.modal.element?.coefficient, 
        drop: false,
      }]
    }),

    modalSpeedRemove: ths => ({
      title: "Удалить время открытия", 
      notification: 'Время открытия успешно удалено', 
      id: 'speed-remove', 
      request: 'api/speed', 
      message: 'это время открытия', 
      method: 'delete', 
      emit: 'removeSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
      },
    }),
  },
};