import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

import promiseWrapper from '../utils/promiseWrapper';
import DateFilter from '@/filters/date.filter';
import CONST from '../constants';

const store = {};

store.actions = {
  onSoundSkip({ rootGetters }) {
    return promiseWrapper(async () => {
      const date = DateFilter(new Date(), 'datehour', true, true);
      const options = { sound_id: rootGetters.sound._id, date };
      axios.put(CONST.path.api.analytics.SOUND_SKIP, options, rootGetters.axiosOptions);
      return true;
    });
  },
  onSoundLaunch({ rootGetters }) {
    return promiseWrapper(async () => {
      const date = DateFilter(new Date(), 'datehour', true, true);
      const options = { sound_id: rootGetters.sound._id, date };
      axios.put(CONST.path.api.analytics.SOUND_LAUNCH, options, rootGetters.axiosOptions);
      return true;
    });
  },
};

export default store;