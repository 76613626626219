<template>
  <transition name="fade">
    <div class="modalover" v-if="isShowModal">
      <div class="overflow" @click="onVideoSkip"/>
      <div class="modal">
        <div v-if="isShowCloseButton" class="cancel" @click="onVideoClose">
          <svg height="329pt" fill="#fff" viewBox="0 0 329.26933 329" width="329pt"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
          </svg>
        </div>
        <div class="container">
          <video autoplay @ended="onVideoEnded" @error="onVideoError">
            <source :src="video" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
          </video>
        </div>
      </div>
    </div>
  </transition>
</template>


<script type="text/javascript">
export default {
  name: 'GameInstructionVideo',

  props: {
    video: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isShowModal: false,
    isShowCloseButton: false,
  }),

  watch: {
    isShowModal: {
      immediate: true,
      handler(isShowModal) {
        if (!isShowModal) return;
        this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_START);
      },
    },
  },

  methods: {
    showModal(isShowCloseButton, isFirstShow) {
      if (!this.video) return this.onVideoError();
      this.isShowModal = true;
      this.isShowCloseButton = isShowCloseButton;
      if (!isFirstShow) return;
      this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_FIRST_START);
    },
    hideModal() {
      this.isShowModal = false;
      this.isShowCloseButton = false;
      this.$emit('exit', true);
    },
    onVideoEnded() {
      this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_COMPLETE);
      this.hideModal();
    },
    onVideoClose() {
      this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_CLOSE);
      this.hideModal();
    },
    onVideoSkip() {
      this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_SKIP);
      this.hideModal();
    },
    onVideoError() {
      this.$metrika.add(this.$metrika.consts.APP_INSTRUCTION_ERROR);
      this.hideModal();
    },
  },
};
</script>


<style lang="scss" scoped>
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgb(34 34 34 / 90%);
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: calc(100% - 50px);
  max-width: 500px;
  max-height: 90%;
  overflow: visible;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  background-color: #b490f1;
  border-radius: 10px;
  box-shadow: 0px 0px 17.1607px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;

  .cancel {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b490f1;
    cursor: pointer;

    > svg {
      width: 15px;
      height: 15px;
    }
  }

  .title {
    font-size: 18px;
    text-align: center;
    margin: 15px 0;
  }

  .center {
    text-align: center;
  }

  .container {
    video {
    	border-radius: 10px;
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 1px), 0 calc(100% - 1px));
      width: 100%;
    }

    label {
      display: flex;
      align-items: center;

      >input {
        &::before {
          border-color: #ffffff;
        }
      }

      span {
        margin-left: 10px;
      }
    }
  }
}
</style>