export default {
  computed: {
    modalFilmAdd: ths => ({
title: "Добавить фильм", notification: 'Фильм успешно добавлен', id: 'film-add', request: 'api/film', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
        },
        inputs: [
          {name: 'Фильм *', placeholder: 'Название фильма', id: 'name', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalFilmEdit: ths => ({
title: "Редактировать фильм", notification: 'Фильм успешно отредактирован', id: 'film-edit', request: 'api/film', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
        },
        inputs: [
          {name: 'Фильм *', placeholder: 'Название фильма', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
        ]
    }),

    modalFilmRemove: ths => ({
title: "Удалить фильм", notification: 'Фильм успешно удален', id: 'film-remove', request: 'api/film', message: 'этот фильм', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};