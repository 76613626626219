import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

const store = {};

store.state = () => ({
  prizes: {},
  selectedPrizeId: null,
});

store.getters = {
  prizesList: state => state.prizes,
  selectedPrizeId: state => state.selectedPrizeId,
  selectedPrize: ({}, { prizesList, selectedPrizeId }) => _.get(prizesList, selectedPrizeId, {}),
};

store.actions = {
  createPrize({ rootState, commit }, prize) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = { headers: {}, params: prize };
        options.headers.token = rootState.token;
        const response = await axios({
          method: 'post',
          url: '/api/prize',
          headers: { token: rootState.token },
          data: options.params,
        });
        resolve(response.data);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  getPrizes({ rootState, commit }, { filter, find, ids, ignoreExisting = false, params = {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (Array.isArray(ids) && !ids.length) return resolve({});
        const rootPrizesList = _.keys(rootState.prizes);
        const options = { headers: {}, params };
        options.headers.token = rootState.token;
        options.params.filter = filter;
        options.params.find = find;
        options.params.ids = ignoreExisting ? _.values(_.omit(ids, rootPrizesList)) : ids;
        const response = await axios.get('/api/prize', options);
        const prizes = response.data.reduce((acc, prize) => ({ ...acc, [prize._id]: prize }), {});
        commit('addPrizes', prizes);
        resolve(prizes);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  editPrizeById({ rootState, commit }, prize) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = { headers: {}, params: {} };
        options.headers.token = rootState.token;
        options.params.id = prize._id;
        options.params.newPrize = prize;
        const response = await axios({
          method: 'put',
          url: '/api/prize',
          headers: { token: rootState.token },
          data: options.params,
        });
        const result = { [id]: response.data };
        commit('addPrizes', result);
        resolve(result);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  deletePrizeById({ rootState, commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const options = { headers: {}, params: {} };
        options.headers.token = rootState.token;
        options.params.id = id;
        const response = await axios({
          method: 'delete',
          url: '/api/prize',
          headers: { token: rootState.token },
          data: options.params,
        });
        resolve(response.data);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
};

store.mutations = {
  addPrizes(state, newPrizes) {
    Vue.set(state, 'prizes', {
      ...state.prizes,
      ...newPrizes,
    });
  },
  addPrize(state, newPrize = {}) {
    if (!newPrize._id) return;
    Vue.set(state.prizes, newPrize._id, newPrize);
  },
  editPrize(state, newPrize = {}) {
    const oldPrize = _.get(state.prizes, newPrize._id, {});
    const updatedPrize = _.defaultsDeep({}, newPrize, oldPrize);
    oldPrize && Vue.set(state.prizes, newPrize._id, updatedPrize);
  },
  removePrizeById(state, id) {
    if (!state.prizes[id]) return;
    Vue.delete(state.prizes, id);
  },
  setSelectedPrizeId(state, newPrizeId) {
    Vue.set(state, 'selectedPrizeId', newPrizeId);
  },
};

export default store;