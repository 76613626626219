import difficulty from './difficulty';
import score from './score';
import speed from './speed';
import genre from './genre';
import language from './language';
import executor from './executor';
import author from './author';
import film from './film';
import filter from './filter';
import library from './library';
import catalog from './catalog';
import stats from './stats';
import prize from './prize';
import stock from './stock/';
import admin from './admin';
import user from './user';
import newsfeed from "./newsfeed";

export default {
  mixins: [
    difficulty,
    score,
    speed,
    genre,
    language,
    executor,
    author,
    film,
    filter,
    library,
    catalog,
    stats,
    prize,
    stock,
    admin,
    user,
    newsfeed
  ],

  computed: {
    modalsOption() {
      return [
        this.modalDifficultyAdd,
        this.modalDifficultyEdit,
        this.modalDifficultyRemove,

        this.modalScoreEdit,
        this.modalScoreRemove,

        this.modalSpeedAdd,
        this.modalSpeedEdit,
        this.modalSpeedRemove,

        this.modalNewsAdd,
        this.modalNewsEdit,
        this.modalNewRemove,

        this.modalGenreAdd,
        this.modalGenreEdit,
        this.modalGenreRemove,

        this.modalLanguageAdd,
        this.modalLanguageEdit,
        this.modalLanguageRemove,

        this.modalExecutorAdd,
        this.modalExecutorEdit,
        this.modalExecutorRemove,

        this.modalAuthorAdd,
        this.modalAuthorEdit,
        this.modalAuthorRemove,

        this.modalFilmAdd,
        this.modalFilmEdit,
        this.modalFilmRemove,

        this.modalFilterAdd,
        this.modalFilterEdit,
        this.modalFilterRemove,

        this.modalLibraryAdd,
        this.modalLibraryEdit,
        this.modalLibraryRemove,
        this.modalStatsGet,

        this.modalCatalogAdd,
        this.modalCatalogEdit,
        this.modalCatalogRemove,

        this.modalPrizeAdd,
        this.modalPrizeEdit,
        this.modalPrizeRemove,

        this.modalStockAdd,
        this.modalStockEdit,
        this.modalStockRemove,
        this.modalStockPrizesAdd,
        this.modalStockPrizesEdit,
        this.modalStockPrizesRemove,
        // this.modalStockMembersAdd,
        this.modalStockMembersEdit,
        // this.modalStockMembersRemove,
        this.modalStockWinnersAdd,
        this.modalStockWinnersEdit,
        this.modalStockWinnersRemove,

        this.modalAdminAdd,
        this.modalAdminEdit,
        this.modalAdminRemove,

        this.modalUserEdit,
        this.modalUserRemove,
      ].reduce((acc, modal) => ({ ...acc, [modal.id]: modal }), {});
    },
  },

  watch: {
    'modal_content.variables': {
      deep: true,
      immediate: true,
      handler(variables) {
        try {
          const funcId = this.modal_content.emit
            .replace('create', '')
            .replace('remove', '')
            .replace('edit', '');
          this[`onModal${ funcId }Update`](variables);
        } catch(err) {};
      },
    }
  }
};