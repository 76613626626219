export const statuses = [
  { 
    _id: 'active', 
    name: 'Активен',
    color: 'limegreen',
    title: 'Виден пользователям, работает штатно',
  }, { 
    _id: 'paused', 
    name: 'Пауза',
    color: 'deepskyblue',
    title: 'Виден пользователям, не доступен для использования',
  }, { 
    _id: 'testing', 
    name: 'Тесты',
    color: '#d885ff',
    title: 'Не виден пользователям, доступен для тестирования',
  }, { 
    _id: 'ended', 
    name: 'Окончен', 
    color: 'orangered',
    title: 'Виден только в рейтинге (архиве)',
  }, { 
    _id: 'disabled', 
    name: 'Отключен',
    color: 'crimson',
    title: 'Не виден никому и нигде',
  },
];