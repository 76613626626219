export default {
  computed: {
    modalLanguageAdd: ths => ({
title: "Добавить язык", notification: 'Язык успешно добавлен', id: 'language-add', request: 'api/language', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
        },
        inputs: [
          {name: 'Язык *', placeholder: 'Название языка', id: 'name', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalLanguageEdit: ths => ({
title: "Редактировать языка", notification: 'Язык успешно отредактирован', id: 'language-edit', request: 'api/language', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
        },
        inputs: [
          {name: 'Язык *', placeholder: 'Название языка', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
        ]
    }),

    modalLanguageRemove: ths => ({
title: "Удалить язык", notification: 'Язык успешно удален', id: 'language-remove', request: 'api/language', message: 'этот язык', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};