<template>
  <section id="app-container">
    <div class="game" ref="game">

      <div v-if="['Game', 'GamePlay'].includes($router.currentRoute.name)" class="info-widgets">
        <div class="wiget-item">
          <IconSubscriptionStatus/>
        </div>
        <div class="wiget-item" v-if="hasHotNews">
          <IconHotNews />
        </div>
      </div>

      <div v-if="showMenus" :class="['game__menu', { game__menu_open: showDrop }]">
        <svg 
          width="22" 
          height="16" 
          viewBox="0 0 22 16" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          @click="showDrop = !showDrop"
        >
          <template v-if="showDrop">
            <line x1="1" y1="6" x2="12" y2="16" stroke="#620CEF" stroke-width="2"/>
            <line x1="12" y1="6" x2="1" y2="16" stroke="#620CEF" stroke-width="2"/>
          </template>
          <template v-else>
            <path d="M0 1H22" :stroke="showMenuColor" stroke-width="2"></path>
            <path d="M5 7.5H22" :stroke="showMenuColor" stroke-width="2"></path>
          </template>
        </svg>

        <transition name="fade" >
          <div class="drop" v-if="showDrop">
            <ul>
              <li v-for="el of dropElements" :key="el.id" @click.stop="el.action(el)">
                <i :class="`icon ${el.id}-i`"/>
                <span v-text="el.name"/>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <router-view v-if="isShowPage" />
    </div>
  </section>
</template>


<script>
import _ from 'lodash';
import axios from "axios";
import DateFilter from '@/filters/date.filter';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import IconSubscriptionStatus from "@/components/icons/IconSubscriptionStatus.vue";
import IconHotNews from "@/components/icons/IconHotNews.vue";

export default {
  name: "game-layout",

  components: {
    IconHotNews,
    IconSubscriptionStatus,
  },

  data: ths => ({
    lodash: _,
    showDrop: false,
    showMenus: true,
  }),

  computed: {
    ...mapGetters([
      'user',
      'launch',
      'axiosOptions',
      'isDemoUser',
      'hasHotNews',
    ]),

    showMenuColor: ths => ['Game', 'GamePlay', 'GamePlayDemo'].includes(ths.$router.currentRoute.name) ? '#fff' : '#333',

    isShowPage() {
      if (this.$router.currentRoute.meta.demo) return true;
      if (!this.user) return false;
      return ![0, 4].includes(this.user.role);
    },

    dropElements() {
      const action = this.selectPage;
      const logoutHandler = this.$store.dispatch.bind(this, 'logout');
      if (!this.isDemoUser && ![0, 4].includes(this.user.role)) {
        return [
          { id: 'game', name: 'Игра', action, route: '/', blank: false },
          { id: 'top', name: 'Топ игроков', action, route: '/top' , blank: true },
          { id: 'profile', name: 'Профиль', action, route: '/profile', blank: true },
          { id: 'newsfeed', name: 'Новости', action, route: '/newsfeed', blank: true },
          { id: 'help', name: 'Правила игры', action, route: '/help', blank: true },
          { id: 'logout', name: 'Выйти', action: logoutHandler, blank: true },
        ];
      } else {
        return [
          { id: 'logout', name: 'Войти', action: logoutHandler, blank: true },
        ];
      };
    },
  },

  watch: {
    '$store.getters.soundOfHistory'(soundOfHistory = {}) {
      this.$store.commit('setCurrentSoundId', soundOfHistory.id);
    },
  },

  created() {
    if (this.$router.currentRoute.meta.demo) this.setUserRole(4);
  },

  async mounted() {
    const { name, query } = this.$router.currentRoute;
    if (name === 'ameProfile' && query.payload) return this.checkUser();
    this.checkLaunch();
    await this.$store.dispatch('checkGameToken');
    this.$store.dispatch('getUserData');
  },

  methods: {
    ...mapMutations(['setUserRole']),

    async checkUser() {
      const query = this.$router.currentRoute.query;
      const isMD5 = text => (/[a-fA-F0-9]{32}/).test(text);
      if (this.$store.getters.token) return this.reloadPage();
      if (query?.success !== 'success') return this.reloadPage();
      if (!isMD5(query?.sign)) return this.reloadPage();
      try {
        await axios.post('/api/user/check/register', query);
        this.$router.push('/auth');
      } catch(err) {
        console.log(err.response);
        if (err.response.status === 404)
          return this.$router.push({ name: 'GameAuth', query: { register: true, ...query } });
        this.$bus.$emit("showNotification", {
          status: "danger-game",
          message: err.response.data,
        });
        this.reloadPage();
      };
    },

    reloadPage() {
      this.$router.replace(Object.assign({ query: {} }, this.$router.currentRoute));
      location.reload();
    },

    selectPage(element) {
      this.showDrop = false;
      if (element.blank) return window.open(element.route);
      this.$router.push(element.route);
    },

    async checkLaunch() {
      if (this.launch && Math.ceil((Date.now() - +new Date(this.launch)) / 1000) <= 1800) return;
      try {
        const platform = this.getCurrentPlatform();
        const date = DateFilter(new Date(), 'datehour', true, true);
        await axios.put(`/api/analytics/launch`, { date, platform }, this.axiosOptions);
        this.$store.dispatch('createLaunch');
      } catch(err) {
        console.error(err);
      };
    },

    getCurrentPlatform() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (/Android/i.test(navigator.userAgent)) return 'android';
        if (/iPod|iPhone|iPad/i.test(navigator.userAgent)) return 'ios';
      } else {
        return 'web';
      };
    },
  },
};
</script>


<style lang="scss">
$font-family: 'Roboto', sans-serif;
.home-btn{
  position: absolute;
  top:20px;
  left: 35px;
  z-index: 101;
}
.page-title {
  color: #000;
  padding: 65px 0 0 35px;
  font-weight: 700;
  font-size: 28px;
  line-height: 60px;
}
#app-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(240, 249, 255);

  .game {
    overflow: auto;
    background-color: #fff;
    background-image: none;
    width: 100%;
    height: 100%;
    font-family: $font-family;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;

    .landscape {
      width: 100%;
      height: 100%;
    }

    &.portrait {
      max-width: 600px;
      //max-height: 823px;
      min-height: 568px;
      width: 100%;
      height: 100%;
    }

    &.landscape {
      max-width: 823px;
      max-height: 414px;

      .btn {
        height: 39px !important;
      }

      input:not([type="checkbox"]) {
        padding: 10px 15px !important;
      }

      .input__block {
        span {
          font-size: 13px;
        }

        svg {
          width: 11px;
          bottom: 14px;
        }
      }
    }

    &__menu {
      position: absolute;
      top: 25px;
      z-index: 1;
      right: 0;
      width: 100%;

      svg {
        float: right;
        margin-right: 25px;
        cursor: pointer;
      }

      .drop {
        // position: absolute;
        // width: 150px;
        // top: 110%;
        // right: 0;
        // background-color: #100428;
        // border-radius: 25px 0 25px 25px;
        // box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.8);
        // padding: 15px 20px;
    
        background-color: #fff;
        border-radius: 25px 25px 25px 25px;
        margin: 0 25px;
        padding: 15px 20px;
        box-shadow: 0 5px 10px -5px #6461f6;
        ul {
          li {
            cursor: pointer;
            padding: 7px 0;
            color: #4E4B66;
            display: flex;
            align-items: center;
            span {
              margin-top: 3px;
            }
          }
        }
      }
    }
    .game__menu_open{
      z-index: 3 !important;
    }
    .input__block {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      span {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: 500;
      }

      input.active {
        border-radius: 10px 10px 0 0;
        transition: 0.2s all;
      }
    }

    input::placeholder {
      color: #333333 !important;
    }

    input,
    .btn-main {
      background: #EFF0F7;
     // border: 2px solid #FFFFFF;
      border-radius: 10px;
      font-family: $font-family;
      color: #3d3a3a;

      &::-webkit-input-placeholder {
        color: #333333;
      }
    }

    .btn {
      font-family: $font-family;
      text-transform: none;
      font-weight: 500;
      border-radius: 10px;
      width: 100%;
      height: 49px;
      font-size: 16px;
      background-color: #A996FF;
      border: 3px solid #4E4B66;
      etter-spacing: 0.75px;
      color: #4700AB;
      font-weight: 600;

    }

    .btn-gradient {
      background: linear-gradient(260.45deg, rgba(232, 90, 255, 0.81) 6.04%, rgba(71, 145, 255, 0.81) 94.04%);
    }

    .btn-div {
      display: flex;
      justify-content: center;
    }

    .btn-disabled {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}

.icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-position: 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  padding-right: 35px;
}

.icon.game-i {
  background-image: url(../assets/Home.svg);
}

.icon.newsfeed-i {
  background-image: url(../assets/Newsfeed.svg);
}

.icon.help-i {
  background-image: url(../assets/Read\ Book.svg);
}

.icon.profile-i {
  background-image: url(../assets/Profile.svg);
}

.icon.top-i {
  background-image: url(../assets/Chart-bar.svg);
}

.icon.logout-i {
  background-image: url(../assets/Log\ Out\ Square.svg);
}


.info-widgets {
  position: absolute;
  left: 20px;
  top:20px;
  display: flex;
  z-index: 2;
}

.wiget-item {
}

.wiget-item:last-child {
  padding-left: 10px;
}

</style>