import axios from 'axios';
import router from '@/router/index';

const store = {};
store.actions = {
  createToken: ({ commit }, token) => commit('setToken', token),

  async checkToken({ getters }) {
    try {
      const headers = { token: getters.token };
      headers['Content-type'] = 'application/json; charset=UTF-8';
      const response = await axios('/api/token', { headers });
      if (router.currentRoute.name !== 'Auth') return;
      router.push('/admin/');
    } catch (err) {
      console.error(err);
      if (router.currentRoute.name === 'Reset') return;
      router.push('/admin/auth');
    };
  },

  async checkGameToken({ getters }) {
    try {
      const headers = { token: getters.token };
      headers['Content-type'] = 'application/json; charset=UTF-8';
      const response = await axios('/api/token', { headers });
      if (router.currentRoute.name !== 'GameAuth') return;
      router.push('/');
    } catch (err) {
      console.error(err);
      if (router.currentRoute.name === 'GameReset') return;
      router.push('/auth');
    };
  },
};

export default store;