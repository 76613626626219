export default {
  computed: {
    modalCatalogAdd: ths => ({
title: "Добавить песню",  notification: 'Песня успешно добавлена', id: 'catalog-add', request: 'api/sound', method: 'post', emit: 'createSound', file: null, file_request: null, imageUpload: true,
        variables: {
          name: '',
          music_author: [],
          text_author: [],
          executor: [],
          fragment: '',
          fragment_order: '',
          sound_id: '',
          status: '',
          difficulty: '',
          genre: [],
          language: [],
          film: '',
          filter: '',
          photo: '',
          rights_holder: ''
        },
        inputs: [
          {name: 'Название *', placeholder: 'Название песни', id: 'name', grid: '1 / 4', value: '', drop: false},
          {name: 'Музыка', placeholder: 'Автор музыки', id: 'music_author', grid: '4 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Текст', placeholder: 'Автор текста', id: 'text_author', grid: '1 / 4', value: '', drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Исполнитель', placeholder: 'Исполнитель песни', id: 'executor', grid: '4 / 7', value: '', drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Фрагмент *', placeholder: 'Фрагмент песни', id: 'fragment', grid: '1 / 7', value: ''},
          {name: 'Порядок', placeholder: 'Порядок слов', id: 'fragment_order', grid: '1 / 7', value: ''},
          {name: 'ID *', placeholder: '0', id: 'sound_id', grid: '1 / 3', value: '', mask: '######'},
          {name: 'Статус *', placeholder: 'Статус', id: 'status', grid: '3 / 5', value: '', drop: true, showDrop: false, context: '', drop_data: [{ _id: 'Активна', name: 'Активна' }, { _id: 'Неактивна', name: 'Неактивна' }]},
          {name: 'Сложность *', placeholder: 'Сложность', id: 'difficulty', grid: '5 / 7', value: '', drop: true, showDrop: false, context: ''},
          {name: 'Жанр', placeholder: 'Жанр', id: 'genre', grid: '1 / 3', value: '', drop: false, showDrop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Язык', placeholder: 'Язык', id: 'language', grid: '3 / 5', value: '', drop: false, showDrop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Фильм', placeholder: 'Фильм', id: 'film', grid: '5 / 7', value: '', drop: true, showDrop: false, context: ''},
          {name: 'Сайт-фильтр', placeholder: 'Сайт-фильтр, комментарий', id: 'filter', grid: '1 / 7', value: '', drop: true, showDrop: false, context: ''},
          {name: 'ПО', placeholder: 'Название правообладателя', id: 'rights_holder', grid: '1 / 7', value: '', drop: true, showDrop: false, context: ''},
        ]
    }),

    modalCatalogEdit: ths => ({
title: "Редактировать песню",  notification: 'Песня успешно отредактирована', id: 'catalog-edit', request: 'api/sound', method: 'put', emit: 'editSound', file: null, file_request: null, imageUpload: true,
        variables: {
          _id: ths.modal.element._id,
          name: ths.modal.element.name,
          music_author: ths.modal.element.music_author_id,
          music_author_id: ths.modal.element.music_author_id?.map(el => el._id),
          text_author: ths.modal.element.text_author_id,
          text_author_id: ths.modal.element.text_author_id?.map(el => el._id),
          executor: ths.modal.element.executor_id,
          executor_id: ths.modal.element.executor_id?.map(el => el._id),
          fragment: ths.modal.element.fragment,
          fragment_order: ths.modal.element.fragment_order,
          sound_id: ths.modal.element.sound_id,
          status: ths.modal.element.status_id,
          status_id: ths.modal.element.status_id,
          difficulty: ths.modal.element.difficulty_id?.name,
          difficulty_id: ths.modal.element.difficulty_id?._id,
          genre: ths.modal.element.genre_id,
          genre_id: ths.modal.element.genre_id?.map(el => el._id),
          language: ths.modal.element.language_id,
          language_id: ths.modal.element.language_id?.map(el => el._id),
          film: ths.modal.element.film_id?.name,
          film_id: ths.modal.element.film_id?._id,
          filter: ths.modal.element.filter_id?.name,
          filter_id: ths.modal.element.filter_id?._id,
          photo: ths.modal.element.photo,
          rights_holder: ths.modal.element.rights_holder
        },
        inputs: [
          {name: 'Название *', placeholder: 'Название песни', id: 'name', grid: '1 / 4', value: ths.modal.element.name, drop: false},
          {name: 'Музыка', placeholder: 'Автор музыки', id: 'music_author', grid: '4 / 7', value: ths.modal.element.music_author_id?.name, drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Текст', placeholder: 'Автор текста', id: 'text_author', grid: '1 / 4', value: ths.modal.element.text_author_id?.name, drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Исполнитель', placeholder: 'Исполнитель песни', id: 'executor', grid: '4 / 7', value: ths.modal.element.executor_id?.name, drop: false, showBoxDrop: false, dropBoxs: true, context: ''},
          {name: 'Фрагмент *', placeholder: 'Фрагмент песни', id: 'fragment', grid: '1 / 7', value: ths.modal.element.fragment},
          {name: 'Порядок', placeholder: 'Порядок слов', id: 'fragment_order', grid: '1 / 7', value: ths.modal.element.fragment_order},
          {name: 'ID *', placeholder: '0', id: 'sound_id', grid: '1 / 3', value: ths.modal.element.sound_id, mask: '######'},
          {name: 'Статус *', placeholder: 'Статус', id: 'status', grid: '3 / 5', value: ths.modal.element.status_id, drop: true, showDrop: false, context: '', drop_data: [{ _id: 'Активна', name: 'Активна' }, { _id: 'Неактивна', name: 'Неактивна' }]},
          {name: 'Сложность *', placeholder: 'Сложность', id: 'difficulty', grid: '5 / 7', value: ths.modal.element.difficulty_id?.name, drop: true, showDrop: false, context: ''},
          {name: 'Жанр', placeholder: 'Жанр', id: 'genre', grid: '1 / 3', value: ths.modal.element.genre_id?.name, drop: false, showDrop: false, dropBoxs: true, showBoxDrop: true, context: ''},
          {name: 'Язык', placeholder: 'Язык', id: 'language', grid: '3 / 5', value: ths.modal.element.language_id?.name, drop: false, showDrop: false, dropBoxs: true, showBoxDrop: false, context: ''},
          {name: 'Фильм', placeholder: 'Фильм', id: 'film', grid: '5 / 7', value: ths.modal.element.film_id?.name, drop: true, showDrop: false, context: ''},
          {name: 'Сайт-фильтр', placeholder: 'Сайт-фильтр, комментарий', id: 'filter', grid: '1 / 7', value: ths.modal.element.filter_id?.name, drop: true, showDrop: false, context: ''},
          {name: 'ПО', placeholder: 'Название правообладателя', id: 'rights_holder', grid: '1 / 7', value: ths.modal.element.rights_holder, drop: true, showDrop: false, context: ''},
        ]
    }),

    modalCatalogRemove: ths => ({
title: "Удалить песню",  notification: 'Песня успешно удалена', id: 'catalog-remove', request: 'api/sound', message: 'этоту песню', method: 'delete', emit: 'removeSound', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};