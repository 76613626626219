export default {
  computed: {
    modalUserEdit: ths => ({
title: "Редактировать игрока", notification: 'Данных игрока успешно изменены', id: 'user-edit', request: 'api/user/score', method: 'put', emit: 'editScore', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          score: ths.modal.element.score,
          type: ths.modal.element.type,
        },
        inputs: [
          {name: 'Баллы *', placeholder: 'Баллы', id: 'score', grid: '1 / 7', value: ths.modal.element.score, drop: false},
          {name: 'Статус аккаунта', placeholder: 'Статус аккаунта', id: 'type', grid: '1 / 7', value: ths.modal.element.type, drop: true, showDrop: false, drop_data: [
            {_id: 'Премиум', name: 'Премиум'}, {_id: 'Бесплатный', name: 'Бесплатный'}
          ]},
        ]
    }),

    modalUserRemove: ths => ({
title: "Удалить игрока", notification: 'Игрок успешно удален', id: 'user-remove', request: 'api/user', method: 'delete', emit: 'removeUser', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};