export default {
  computed: {
    modalGenreAdd: ths => ({
      title: "Добавить жанр", notification: 'Жанр успешно добавлен', id: 'genre-add', request: 'api/genre', method: 'post', emit: 'createDirectory', file: null, file_request: null,
        variables: {
          name: '',
        },
        inputs: [
          {name: 'Жанр *', placeholder: 'Название жанра', id: 'name', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalGenreEdit: ths => ({
title: "Редактировать жанр", notification: 'Жанр успешно отредактирован', id: 'genre-edit', request: 'api/genre', method: 'put', emit: 'editDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element?._id,
          name: ths.modal.element?.name,
        },
        inputs: [
          {name: 'Жанр *', placeholder: 'Название жанра', id: 'name', grid: '1 / 7', value: ths.modal.element.name, drop: false},
        ]
    }),

    modalGenreRemove: ths => ({
      title: "Удалить жанр", notification: 'Жанр успешно удален', id: 'genre-remove', request: 'api/genre', message: 'этот жанр', method: 'delete', emit: 'removeDirectory', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};