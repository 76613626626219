<template>
  <article>
    <IconRepair size="100" color="#14142B"/>
    <main v-text="text"/>
  </article>
</template>

<script>
import IconRepair from '@/components/icons/IconRepair';

export default {
  name: "repair-layout",

  components: {
    IconRepair,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
article {
  width: 100%;
  height: 100%;
  background: linear-gradient(0, #A996FF, #DCD5FF);
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  gap: 1em;
  padding: 40px;
  main {
    text-align: center;
    color: #14142B;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }
}
</style>