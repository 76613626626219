import zeroPadFilter from './zeroPad.filter';

export default function dateFilter(value, format = "date") {
  let filter = [];
  if (format.includes("date")) {
    filter.push(
      `${zeroPadFilter(value.getDate(), 2)} ${monthString(
        value.getMonth() + 1
      )} ${value.getFullYear()}г.`
    );
  }
  if (format.includes("time")) {
    filter.push(`${zeroPadFilter(value.getHours(), 2)}:${zeroPadFilter(value.getMinutes(), 2)}`);
  }
  return filter.join(", ");
}

function monthString(number) {
  return [
    { number: 1, string: "января" },
    { number: 2, string: "февраля" },
    { number: 3, string: "марта" },
    { number: 4, string: "апреля" },
    { number: 5, string: "мая" },
    { number: 6, string: "июня" },
    { number: 7, string: "июля" },
    { number: 8, string: "августа" },
    { number: 9, string: "сентября" },
    { number: 10, string: "октября" },
    { number: 11, string: "ноября" },
    { number: 12, string: "декабря" },
  ].find((month) => month.number === number).string;
}
