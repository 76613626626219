export default {
  computed: {
    modalScoreEdit: ths => ({
      title: "Редактировать баллы", 
      notification: 'Баллы успешно отредактированы', 
      id: 'score-edit', 
      request: 'api/score', 
      method: 'put', 
      emit: 'editSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element?._id,
        score: ths.modal.element?.score,
      },
      inputs: [{
        name: 'Баллы *', 
        placeholder: '±000', 
        id: 'score', 
        grid: '1 / 1', 
        value: ths.modal.element?.score, 
        drop: false
      }]
    }),

    modalScoreRemove: ths => ({
      title: "Удалить баллы", 
      notification: 'Баллы успешно удалены', 
      id: 'score-remove', 
      request: 'api/score', 
      message: 'эти баллы', 
      method: 'delete', 
      emit: 'removeSetting', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
      },
    }),
  },
};