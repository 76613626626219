/*
  Обьект метрики состоит из 4х ключей:
  description - описание в консоль для удобства расшифровки
  type - тип вызываемого события (берется из Я.Метрика)
    По умолчанию: "reachGoal"
  limit - сколько раз данное событие можно вызвать за сессию (до перезагрузки страницы)
    По умолчанию: Infinity
  id - идентификатор вызываемого события (берется из Я.Метрика)
*/
import _ from 'lodash';

import * as authMetrika from './auth.js';
import * as gameMetrika from './game.js';
import * as appMetrika from './app.js';

export default Object.entries({
  ...authMetrika,
  ...gameMetrika,
  ...appMetrika,
}).reduce((acc, [key, value]) => {
  if (typeof value.id !== 'string')
    value.id = _.kebabCase(key);
  acc[key] = { ...value, key };
  return acc;
}, {});

