<template>
  <div class="modalover">
    <div class="overflow" @click="removeModal"></div>
    <div class="modal">
      <div class="modal__container">
        <div class="modal__container_title flex justify_between items_center" v-if="modal_content">
          <span>{{ modal_content.title }}</span>
          <div v-if="modal.element.img" class="remove_img" @click="removeImage">
            <svg fill="#A2A1C0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve"><g><g><path d="M294.111,256.001L504.109,46.003c10.523-10.524,10.523-27.586,0-38.109c-10.524-10.524-27.587-10.524-38.11,0L256,217.892 L46.002,7.894c-10.524-10.524-27.586-10.524-38.109,0s-10.524,27.586,0,38.109l209.998,209.998L7.893,465.999 c-10.524,10.524-10.524,27.586,0,38.109c10.524,10.524,27.586,10.523,38.109,0L256,294.11l209.997,209.998 c10.524,10.524,27.587,10.523,38.11,0c10.523-10.524,10.523-27.586,0-38.109L294.111,256.001z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
          </div>
          <label>
            <div v-if="modal_content.action !== 'remove' && modal_content.imageUpload" :class="{upload: modal.element.img || preview_file}" class="img flex justify_center items_center">
              <img v-if="modal.element.img && !preview_file" :src="modal.element.img" alt="">
              <img v-else-if="preview_file" :src="preview_file" alt="">
              <svg v-else width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25 0H3C1.34315 0 0 1.34315 0 3V17C0 18.6569 1.34315 20 3 20H25C26.6569 20 28 18.6569 28 17V3C28 1.34315 26.6569 0 25 0ZM26 17C26 17.5523 25.5523 18 25 18H3C2.4477 18 1.99998 17.5523 1.99998 17V16.414L6.00001 12.414L9.29304 15.707C9.68351 16.0974 10.3166 16.0974 10.707 15.707L19 7.41399L26.0001 14.414V17H26ZM26 11.586L19.707 5.29301C19.3166 4.9026 18.6835 4.9026 18.293 5.29301L10 13.586L6.707 10.2929C6.31653 9.90253 5.68348 9.90253 5.29301 10.2929L1.99998 13.586V3C1.99998 2.4477 2.4477 1.99998 3 1.99998H25C25.5523 1.99998 26.0001 2.4477 26.0001 3V11.586H26Z" fill="#9E9DBB"/>
                <path d="M9 4C7.34314 4 6 5.34315 6 7C6 8.65686 7.34314 10 9 10C10.6569 10 12 8.65686 12 7C12 5.34315 10.6569 4 9 4ZM9 8.00002C8.4477 8.00002 7.99998 7.55231 7.99998 7C7.99998 6.4477 8.4477 5.99998 9 5.99998C9.5523 5.99998 10 6.4477 10 7C10 7.55231 9.5523 8.00002 9 8.00002Z" fill="#9E9DBB"/>
              </svg>
            </div>
            <input @change="uploadImg" type="file" name="img" ref="img">
          </label>
        </div>
        <div 
          v-if="modal_content"
          class="modal__container_content"
        >
          <div class="inputs" v-if="!modal_content.id.includes('remove')">
            <div
              class="input__block"
              v-for="input of modal_content.inputs"
              :key="input.id"
              :style="{
                'grid-row': input.gridH,
                'grid-column': input.grid,
              }"
            >
              <span>{{ input.name }}</span>
              <img
                v-if="input.image"
                :ref="input.id"
                :disabled="true"
                :src="modal_content.variables[input.id]"
              />
              <div v-else style="position: relative;">
                <input
                  v-if="input.drop"
                  :ref="input.id"
                  :placeholder="input.placeholder"
                  :disabled="input.disabled"
                  type="text"
                  :style="{ cursor: input.onlyDrop ? 'pointer' : null }"
                  @click="changeShowDrop(input, modal_content.inputs)"
                  @keydown="input.onlyDrop && $event.preventDefault()"
                  v-model.trim="modal_content.variables[input.id]"
                />
                <div
                  v-else-if="input.dropBoxs"
                  @click="changeShowBoxDrop(input, modal_content.inputs)"
                  :disabled="input.disabled"
                  :ref="input.id"
                  class="input"
                >
                  <div class="content" v-if="modal_content.variables[input.id] && Array.isArray(modal_content.variables[input.id])">
                    {{modal_content.variables[input.id].map(el => el.name).join(', ')}}
                  </div>
                  <div class="content" v-else>
                    {{modal_content.variables[input.id]}}
                  </div>
                </div>
                <input
                  :ref="input.id"
                  v-else-if="input.mask"
                  v-mask="input.mask"
                  type="text"
                  :placeholder="input.placeholder"
                  :disabled="input.disabled"
                  v-model.trim="modal_content.variables[input.id]"
                  :class="{birthday: input.name === 'Дата рождения' }"
                />
                <textarea
                  v-else-if="input.multiline"
                  :placeholder="input.placeholder"
                  :disabled="input.disabled"
                  rows="2"
                  v-model.trim="modal_content.variables[input.id]"
                />
                <input
                  v-else
                  type="text"
                  :disabled="input.disabled"
                  :placeholder="input.placeholder"
                  v-model.trim="modal_content.variables[input.id]"
                />
                <svg 
                  v-if="input.drop || input.dropBoxs" 
                  :class="{ active: input.showDrop, disabled: input.disabled }" 
                  :disabled="input.disabled"
                  :ref="[input.id, 'drop'].join('_')"
                  width="10" 
                  height="7" 
                  viewBox="0 0 10 7" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"
                  @click="!input.disabled && changeShowDrop(input, modal_content.inputs)"
                >
                  <path d="M1 1L5 5L9 1" stroke-width="2"/>
                </svg>
                <transition name="fade">
                  <div v-if="input.showDrop" class="drop">
                    <ul>
                      <li 
                        v-for="el of input.drop_data" 
                        :key="el._id"
                        :title="el.title"
                        style="cursor: pointer;"
                        @click="selectElement(el, input.id)" 
                        v-text="el.name || el.login"
                      />
                    </ul>
                  </div>
                </transition>
                <transition name="fade">
                  <div v-if="input.showBoxDrop" class="drop">
                    <ul>
                      <li>
                        <input class="search" type="text" v-model="input.context" placeholder="Поиск" @keyup="contextSearch(input)">
                      </li>
                      <li @click="selectBoxElement(el, input.id, input.context_data)" v-for="el of input.drop_data" :key="el._id">
                        <input class="checkbox" type="checkbox" v-model="el.selected">
                        <span>{{ el.name }}</span>
                      </li>
                    </ul>
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="remove_message" v-else>Вы действительно хотите удалить {{ modal_content.message }}?</div>
          <div class="actions flex justify_between">
            <div class="left">
              <label v-if="modal.modal === 'catalog-add' || modal.modal === 'catalog-edit' || modal.modal === 'library-add' || modal.modal === 'library-edit'">
                <div>
                  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.5516 7.28394C18.2253 6.95936 17.6976 6.96081 17.3731 7.28711L7.35535 17.359C6.05567 18.6587 3.94481 18.6587 2.64271 17.3572C1.34159 16.0555 1.34159 13.9446 2.64287 12.6433L12.9571 2.27489C13.7685 1.46351 15.0877 1.46351 15.9015 2.27665C16.715 3.09018 16.715 4.40923 15.9013 5.223L7.35691 13.7673C7.35637 13.7679 7.3559 13.7685 7.35535 13.769C7.02983 14.0928 6.50377 14.0923 6.17892 13.7675C5.85352 13.4421 5.85352 12.9147 6.17892 12.5893L10.3032 8.46412C10.6286 8.13865 10.6286 7.61098 10.3031 7.28558C9.97763 6.96019 9.44996 6.96022 9.12457 7.2857L5.0003 11.4108C4.02407 12.387 4.02407 13.9697 5.00038 14.946C5.97665 15.9222 7.55934 15.9222 8.53565 14.946C8.53678 14.9448 8.53768 14.9436 8.53877 14.9425L17.0798 6.40146C18.5445 4.93682 18.5445 2.56248 17.0798 1.09784C15.615 -0.365945 13.2408 -0.365945 11.777 1.09784L1.46277 11.4663C-0.487823 13.4169 -0.487823 16.5828 1.46417 18.5358C3.41745 20.488 6.58338 20.488 8.53549 18.5359L18.5548 8.46248C18.8793 8.13615 18.8779 7.60852 18.5516 7.28394Z" fill="#9E9DBB"/>
                  </svg>
                  <span>Прикрепить</span>
                  <div class="sound" v-if="modal.element.sound && showAudio">{{ modal.element.sound_name }}</div>
                </div>
                <input @change="uploadSound" type="file" name="sound" ref="sound">
              </label>
              <div class="action" v-if="(modal.modal === 'catalog-add' || modal.modal === 'catalog-edit' || modal.modal === 'library-add' || modal.modal === 'library-edit') && showAudio">
                <div class="play" @click="audioHandler(true)" v-if="modal.element.sound && !audio.status">
                  <svg version="1.1" fill="#A2A1C0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 397.2 397.2" style="enable-background:new 0 0 397.2 397.2;" xml:space="preserve"><g><g><path d="M284.2,178l-58-33.6l-57.6-33.2c-5.6-3.2-12-4-17.6-2.4c-5.6,1.6-10.8,5.2-14,10.8 c-1.2,1.6-1.6,3.6-2.4,5.6c-0.4,1.2-0.4,2.8-0.8,4.4c0,0.4,0,1.2,0,1.6v68v68c0,6.4,2.8,12.4,6.8,16.4c4.4,4.4,10,6.8,16.4,6.8 c3.6,0,11.2-3.2,13.2-4.4l56.8-32.8h0.4l0.4-0.4l58.8-34c5.6-3.2,9.2-8.4,10.8-14.4c0.4-1.2,0.4-2.8,0.4-4.4 C297.8,186.8,284.2,178,284.2,178z M276.2,201.6l-58,33.6c-0.4,0-0.8,0.4-0.8,0.4l-56.8,32.8c-0.4,0.4-2.4,1.2-3.2,1.2 s-1.6-0.4-2.4-0.8c-0.4-0.4-0.8-1.6-0.8-2.4v-67.6v-67.6v-0.4c0-0.4,0-0.4,0-0.8c0,0,0-0.4,0.4-0.4c0,0,0.4-0.4,0.4-0.8 c0.4-0.4,1.2-0.8,1.6-1.2c0.8,0,1.6,0,2,0c0.4,0.4,0.8,0.4,1.2,0.8l56.8,32.8c0.4,0.4,0.8,0.4,0.8,0.4h0.4l58,33.6 c0.8,0.4,2.4,1.6,2.4,2.8C278.2,199.6,277,201.2,276.2,201.6z"/><path d="M339,58.4C300.6,19.6,249.8,0,199,0S97.4,19.2,58.6,58C19.8,97.2,0.2,148,0.2,198.8 s19.2,101.6,58,140.4s89.6,58,140.4,58c50.8,0,101.6-19.2,140.4-58c38.8-38.8,58-89.6,58-140.4S377.8,97.2,339,58.4z M325,324.8 c-34.8,34.8-80.4,52.4-126,52.4c-45.6,0-91.2-17.6-126-52.4c-35.2-34.8-52.4-80.4-52.4-126c0-45.6,17.6-91.2,52.4-126 s80.4-52.4,126-52.4c45.6,0,91.2,17.6,126,52.4s52.4,80.4,52.4,126C377.4,244.4,360.2,290,325,324.8z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                </div>
                <div class="play" @click="audioHandler(false)" v-if="modal.element.sound && audio.status">
                  <svg fill="#A2A1C0" enable-background="new 0 0 511.448 511.448" viewBox="0 0 511.448 511.448" xmlns="http://www.w3.org/2000/svg"><path d="m436.508 74.94c-99.913-99.913-261.64-99.928-361.567 0-99.913 99.913-99.928 261.64 0 361.567 99.913 99.913 261.64 99.928 361.567 0 99.912-99.912 99.927-261.639 0-361.567zm-180.784 394.45c-117.816 0-213.667-95.851-213.667-213.667s95.851-213.666 213.667-213.666 213.666 95.851 213.666 213.667-95.85 213.666-213.666 213.666z"/><path d="m298.39 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z"/><path d="m213.057 160.057c-11.598 0-21 9.402-21 21v149.333c0 11.598 9.402 21 21 21s21-9.402 21-21v-149.333c0-11.598-9.401-21-21-21z"/></svg>
                </div>
                <div class="remove" @click="removeSound" v-if="modal.element.sound">
                  <svg fill="#A2A1C0" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve"><g><g><path d="M294.111,256.001L504.109,46.003c10.523-10.524,10.523-27.586,0-38.109c-10.524-10.524-27.587-10.524-38.11,0L256,217.892 L46.002,7.894c-10.524-10.524-27.586-10.524-38.109,0s-10.524,27.586,0,38.109l209.998,209.998L7.893,465.999 c-10.524,10.524-10.524,27.586,0,38.109c10.524,10.524,27.586,10.523,38.109,0L256,294.11l209.997,209.998 c10.524,10.524,27.587,10.523,38.11,0c10.523-10.524,10.523-27.586,0-38.109L294.111,256.001z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                </div>
              </div>
            </div>
            <div class="right">
              <button @click="removeModal" class="btn btn-white">Отмена</button>
              <button @click.prevent="saveData" class="btn btn-gradient" :class="{pending: pending}">
                <span v-if="modal_content.id.includes('edit')">Сохранить</span>
                <span v-else-if="modal_content.id.includes('remove')">Удалить</span>
                <span v-else>Добавить</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import Axios from 'axios';
import axios from 'axios';
import $ from 'jquery';
import dateFilter from '@/filters/date.filter';
import dateServerFilter from '@/filters/date.server.filter';
import '@/utils/datepicker.min';

import modals from './modals';
import { mapGetters } from 'vuex';

export default {
  name: 'CommonModal',

  mixins: [modals],

  data: () => ({
    lodash: _,
    pending: false,
    preview_file: null,
    modal_content: null,
    sound: null,
    img: null,
    showAudio: true,
    audio: {
      status: false,
      src: null,
    },
  }),

  computed: {
    ...mapGetters([
      'storageUrl',
      'token', 
      'modal',
    ]),
  },

  async mounted() {
    if (!this.modal.element) {
      this.modal.element = {};
    }
    if (this.modal.modal === 'library-add' || this.modal.modal === 'library-edit') {
      axios
        .get(`/api/executor/list`, {
          headers: {
            token: this.token
          }
        })
        .then(res => {
          res.data.forEach(el => {
            if (this.modal.element.executor_id?.find(e => e._id === el._id)) el.selected = true;
            else el.selected = false;
          });

          this.modal_content.inputs.find(input => input.id === 'executor').drop_data = res.data;
          this.modal_content.inputs.find(input => input.id === 'executor').context_data = res.data;

        })
        .catch(err => {
          console.error(err);
        });
    }

    if (this.modal.modal === 'catalog-add' || this.modal.modal === 'catalog-edit') {
      axios
        .get(`/api/directories/all`, {
          headers: {
            token: this.token
          }
        })
        .then(res => {
          res.data.author.forEach(el => {
            if (this.modal.element.text_author_id?.find(t => t._id === el._id)) el.selected = true;
            else el.selected = false;
          });
          res.data.music.forEach(el => {
            if (this.modal.element.music_author_id?.find(t => t._id === el._id)) el.selected = true;
            else el.selected = false;
          });
          res.data.executor.forEach(el => {
            if (this.modal.element.executor_id?.find(t => t._id === el._id)) el.selected = true;
            else el.selected = false;
          });
          res.data.genre.forEach(el => {
            if (this.modal.element.genre_id?.find(t => t._id === el._id)) el.selected = true;
            else el.selected = false;
          });
          res.data.language.forEach(el => {
            if (this.modal.element.language_id?.find(t => t._id === el._id)) el.selected = true;
            else el.selected = false;
          });

          this.modal_content.inputs.find(input => input.id === 'music_author').drop_data = res.data.music;
          this.modal_content.inputs.find(input => input.id === 'text_author').drop_data = res.data.author;
          this.modal_content.inputs.find(input => input.id === 'executor').drop_data = res.data.executor;
          this.modal_content.inputs.find(input => input.id === 'difficulty').drop_data = res.data.difficulty;
          this.modal_content.inputs.find(input => input.id === 'genre').drop_data = res.data.genre;
          this.modal_content.inputs.find(input => input.id === 'language').drop_data = res.data.language;
          this.modal_content.inputs.find(input => input.id === 'film').drop_data = res.data.film;
          this.modal_content.inputs.find(input => input.id === 'filter').drop_data = res.data.filter;
          this.modal_content.inputs.find(input => input.id === 'music_author').context_data = res.data.music;
          this.modal_content.inputs.find(input => input.id === 'text_author').context_data = res.data.author;
          this.modal_content.inputs.find(input => input.id === 'executor').context_data = res.data.executor;
          this.modal_content.inputs.find(input => input.id === 'difficulty').context_data = res.data.difficulty;
          this.modal_content.inputs.find(input => input.id === 'genre').context_data = res.data.genre;
          this.modal_content.inputs.find(input => input.id === 'language').context_data = res.data.language;
          this.modal_content.inputs.find(input => input.id === 'film').context_data = res.data.film;
          this.modal_content.inputs.find(input => input.id === 'filter').context_data = res.data.filter;
        })
        .catch(err => {
          console.error(err);
        })
    }
    if (this.modal.modal === 'stock-add' || this.modal.modal === 'stock-edit') {
      this.$nextTick(() => {
        const getEl = id => _.get(this.$refs[id], 0, this.$refs[id]);
        this.registerDatePicker(getEl('start_date'), 'start_date', null);
        this.registerDatePicker(getEl('end_date'), 'end_date', new Date());
      });
    }
    this.modal_content = this.modalsOption[this.modal.modal];
  },
  methods: {
    registerDatePicker(ref, inputId, minDate = new Date()) {
      $(ref).datepicker({
        minDate,
        dateFormat: "dd.mm.yyyy",
        toggleSelected: false,
        onSelect: (formattedDate, date) => {
          const datepicker = $(ref).datepicker().data('datepicker');
          datepicker.hide();
          this.modal_content.inputs.find(input => input.id === inputId).value = dateFilter(date);
          this.modal_content.variables[inputId] = dateFilter(date);
        },
      });
    },
    contextSearch(input) {
      input.showBoxDrop = false;
      input.drop_data = input.context_data.filter(el => el.name.toLowerCase().includes(input.context.toLowerCase()));
      input.showBoxDrop = true;
    },
    removeImage() {
      this.img = null;
      this.modal.element.img = null;
    },
    removeSound() {
      if (this.audio.src) {
        this.audio.src.pause();
        this.audio.src = null;
      }
      this.sound = null;
      this.modal.element.sound = null;
    },
    uploadSound() {
      this.sound = this.$refs.sound.files[0];
      const file = this.sound;
      if (file) {
        if (!file.type.includes('mpeg') && !file.type.includes('wav')) {
          this.sound = null;
          $(this.$refs.sound)[0].value = '';
          return this.$bus.$emit('showNotification', {message: 'Загрузить можно только файлы с расширением mp3 или wav!', status: 'danger'});
        }
        this.showAudio = false;
        this.modal.element.sound = URL.createObjectURL(this.sound);
        this.modal.element.sound_name = file.name.split('.').shift();
        this.audio.src = new Audio(this.modal.element.sound);
        this.showAudio = true;
        this.audio.src.onloadeddata = () => {
          this.modal_content.variables.duration = Math.ceil(this.audio.src.duration);
          this.modal_content.variables.type = file.name.split('.').pop();
        }
      } else {
        this.sound = null;
        $(this.$refs.sound).value('');
        return this.$bus.$emit('showNotification', {message: 'Во время загрузки файла произошла ошибка!', status: 'danger'});
      }
    },
    uploadImg() {
      this.img = this.$refs.img.files[0];
      const file = this.img;
      if (file) {
        let img = new Image();
        img.onload = () => {
          let fr = new FileReader();
          fr.onload = (() => {
              return (e) => {
                this.preview_file = e.target.result;
              };
          })(file);
          fr.readAsDataURL(file);
        };
        img.src = URL.createObjectURL(file);
        if (!(file.type.includes('jpeg') || file.type.includes('png') || file.type.includes('webp') || file.type.includes('svg'))) {
          this.$bus.$emit('showNotification', {message: 'Загрузить можно только файлы с расширением jpeg, jpg, png, svg!', status: 'danger'});
        }
      } else {
        return this.$bus.$emit('showNotification', {message: 'Во время загрузки файла произошла ошибка!', status: 'danger'});
      }
    },
    changeShowDrop(input, inputs) {
      inputs.forEach(el => {
        if (el.id !== input.id) {
          el.showDrop = false;
          el.showBoxDrop = false;
        };
      });
      this.$set(input, 'showDrop', !input.showDrop);
    },
    changeShowBoxDrop(input, inputs) {
      inputs.forEach(el => {
        if (el.id !== input.id) {
          el.showBoxDrop = false;
          el.showDrop = false;
        };
      });
      this.$set(input, 'showBoxDrop', !input.showBoxDrop);
    },
    selectElement(el, variable) {
      this.modal_content.variables[variable] = el.name || el.login;
      this.modal_content.variables[variable + '_id'] = el._id;
      this.modal_content.inputs.forEach(el => el.showDrop = false);
    },
    selectBoxElement(el, variable, drop_data) {
      el.selected = !el.selected;
      this.modal_content.variables[variable] = drop_data.filter(drop => {
        if (drop.selected) return drop.name;
      });
      this.modal_content.variables[variable + '_id'] = drop_data.filter(drop => {
        if (drop.selected) return drop._id;
      }).map(drop => drop._id);
    },
    removeModal() {
      if (this.audio.src) {
        this.audio.src.pause();
        this.audio.src = null;
      }
      this.$emit('removeModal', true);
    },
    saveData() {
      this.pending = true;

      const options = {
        method: this.modal_content.method,
        url: `/${this.modal_content.request}`,
        headers: {
          token: this.token,
        },
      }
      if (['delete', 'get'].includes(this.modal_content.method)) {
        options.params = this.modal_content.variables
      } else {
        options.data = this.modal_content.variables
        if (options.data.img) {
          const regex = new RegExp(this.storageUrl, 'g');
          options.data.img = options.data.img.replace(regex, '').split('#')[0];
        }
      }

      if (this.modal_content.responseType) {
        options.responseType = this.modal_content.responseType;
      };

      axios(options).then(res => {
        if (options.responseType === 'blob') {  
          const href = URL.createObjectURL(res.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', this.modal_content.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
          return;
        };

        this.$bus.$emit(this.modal_content.emit, {data: res.data, id: this.modal_content.id.split('-').shift()});
        this.removeModal();
        this.$emit("showNotification", {message: this.modal_content.notification, status: 'success'});
        this.pending = false;
        if (this.modal_content.file) {
          const formData = new FormData();
          formData.append('recfile', this.modal_content.file);
          axios
            .post(`/${this.modal_content.file_request}`, formData, {
              headers: {
                token: this.token,
                'Content-Type': 'multipart/form-data'
              }
            })
            .catch(err => {
              console.error(err.response);
            })
        }
        if ((this.modal.modal === 'library-add' || this.modal.modal === 'library-edit')) {
          if (this.sound) this.saveLibrarySound(res.data);
          if (!this.modal.element.sound) {
            axios
              .delete('/api/library/sound', {
                headers: {
                  token: this.$store.getters.token
                },
                params: {
                  _id: this.modal.element._id
                }
              })
              .catch(err => {
                console.error(err.response);
              });
          }
        }
        if ((this.modal.modal === 'catalog-add' || this.modal.modal === 'catalog-edit')) {
          if ((this.sound || this.img)) this.saveSoundAndImg(res.data);
          if (!this.modal.element.sound) {
            axios
              .delete('/api/sound/sound', {
                headers: {
                  token: this.$store.getters.token
                },
                params: {
                  _id: this.modal.element._id
                }
              })
              .catch(err => {
                console.error(err.response);
              });
          }
          if (!this.modal.element.img) {
            axios
              .delete('/api/sound/image', {
                headers: {
                  token: this.$store.getters.token
                },
                params: {
                  _id: this.modal.element._id
                }
              })
              .catch(err => {
                console.error(err.response);
              });
          }
        } else if ((this.modal.modal === 'prize-add' || this.modal.modal === 'prize-edit')) {
          if (this.img) this.savePrizeImg(res.data);
          if (!this.modal.element.img && this.modal.element._id) {
            const headers = { token: this.token };
            const params = { _id: this.modal.element._id };
            axios.delete('/api/prize/image', { headers, params })
              .then(response => {
                const newPrize = { ...res.data, img: response.data.img };
                this.$bus.$emit('editPrize', { data: newPrize });
                this.$emit("showNotification", {message: 'Файл успешно удален', status: 'success'});
              })
              .catch(err => {
                console.error(err.response);
                this.$emit("showNotification", {message: 'Произошла ошибка при удалении файла!', status: 'danger'});
              });
          };
        }
      }).catch(err => {
        console.error(err);
        this.$emit("showNotification", {message: err.response.data, status: 'danger'});
        this.pending = false;
      });
    },
    saveLibrarySound(library) {
      const formData = new FormData();
      formData.append('sound', this.sound);
      formData.append('_id', library._id);
      axios
        .put(`/api/library/sound`, formData, {
          headers: {
            token: this.$store.getters.token,
            'Content-Type': 'multipart/form-data'
          },
          params: {
            _id: library._id,
          }
        })
        .then(() => {
          const data = {_id: library._id, img: null, library: null};
          if (this.sound) data.sound = `/upload/library/${library._id}.${this.sound.name.split('.').pop()}`;
          this.$emit("showNotification", {message: 'Песня успешно загружена', status: 'success'});
        })
        .catch(err => {
          console.error(err.response);
          this.$emit("showNotification", {message: 'Произошла ошибка при загрузке файлов!', status: 'danger'});
        })
    },
    savePrizeImg(prize) {
      const formData = new FormData();
      const ext = this.img.name.split('.').slice(-1)[0];
      const imageName = `${ prize._id }.${ext}`;
      formData.append('img', this.img, imageName);
      formData.append('_id', prize._id);
      this.$emit("showNotification", {message: 'Файлы загружаются... Подождите, пожалуйста...', status: 'warning'});
      const headers = { token: this.token };
      headers['Content-Type'] = 'multipart/form-data';
      axios
        .put(`/api/prize/image`, formData, { headers })
        .then(response => {
          const newPrize = { ...prize, img: response.data.img };
          this.$bus.$emit('editPrize', { data: newPrize });
          this.$emit("showNotification", {message: 'Файл успешно загружен', status: 'success'});
        })
        .catch(err => {
          console.error(err.response);
          this.$emit("showNotification", {message: 'Произошла ошибка при загрузке файла!', status: 'danger'});
        })
    },
    saveSoundAndImg(sound) {
      const formData = new FormData();
      formData.append('sound', this.sound);
      formData.append('img', this.img);
      formData.append('_id', sound._id);
      this.$emit("showNotification", {message: 'Файлы загружаются... Подождите, пожалуйста...', status: 'warning'});
      axios
        .put(`/api/sound/files`, formData, {
          headers: {
            token: this.$store.getters.token,
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(() => {
          const data = {_id: sound._id, img: null, sound: null};
          if (this.img) data.img = `/upload/sound/img_${sound._id}.${this.img.name.split('.').pop()}`;
          if (this.sound) data.sound = `/upload/sound/sound_${sound._id}.${this.sound.name.split('.').pop()}`;
          this.$bus.$emit('uploadFiles', data);
          this.$emit("showNotification", {message: 'Файлы успешно загружены', status: 'success'});
        })
        .catch(err => {
          console.error(err.response);
          this.$emit("showNotification", {message: 'Произошла ошибка при загрузке файлов!', status: 'danger'});
        })
    },
    audioHandler(status) {
      this.audio.status = status;
      if (status) {
        if (!this.audio.src) this.audio.src = new Audio(this.modal.element.sound);
        this.audio.src.onloadeddata = () => {
          this.modal_content.variables.duration = Math.ceil(this.audio.src.duration);
        }
        this.audio.src.play();
      } else {
        this.audio.src.pause();
      }
    }
  }
}
</script>

<style lang="scss">
.modalover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 98;
}
.overflow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #3f3f3f63;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 600px;
  max-height: 90%;
  overflow: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 40px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 17.1607px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
  .modal__container {
    &_title {
      color: #00325C;
      font-weight: 500;
      span {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .img {
        width: 60px;
        height: 60px;
        border: 1px dashed #9E9DBB;
        border-radius: 15px;
        cursor: pointer;
        &.upload {
          border: none;
        }
        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 15px;
        }
        input {
          display: none;
        }
        svg {
          width: 35px;
          height: 30px;
        }
      }
    }
    &_content {
      margin-top: 10px;
      .inputs {
        display: grid;
        min-height: 0;
        min-width: 0;
        gap: 10px;
        grid-template-columns: repeat(6, 1fr);
        input[disabled], svg[disabled] {
          cursor: not-allowed;
          opacity: 0.75;
        }
        textarea {
          max-height: 10em;
          min-height: 3em;
          transition: 0.3s all, 0s height;
          resize: vertical;
          box-sizing: content-box;
          overflow: hidden;
        }
      }
      .remove_message {
        font-size: 14px;
        color: #00325C;
      }
      .actions {
        margin-top: 20px;
        .left {
          display: flex;
          align-items: center;
          .action {
            width: 75px;
            display: flex;
            margin-left: 20px;
            cursor: pointer;
            justify-content: flex-end;
            align-items: center;
            .play {
              width: 20px;
              margin-right: 15px;
            }
            .remove {
              width: 15px;
            }
          }
          label {
            > div {
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              svg {
                margin-right: 10px;
              }
              span {
                font-size: 14px;
                color: #9D9CBB;
              }
              .sound {
                position: absolute;
                font-size: 11px;
                bottom: -20px;
                width: 240px;
                color: #413f7d;
              }
            }
          }
        }
      }
    }
  }
}
.remove_img {
  width: 11px;
  position: absolute;
  top: 18px;
  right: 30px;
  cursor: pointer;
}
</style>