import dateServerFilter from '@/filters/date.server.filter';
import { statuses as stockStatuses } from '@/constants/stock.js';

export default {
  computed: {
    modalStockAdd: ths => ({
      title: 'Добавить розыгрыш', 
      notification: 'Розыгрыш успешно добавлен', 
      id: 'stock-add', 
      request: 'api/stock', 
      method: 'post', 
      emit: 'createStock', 
      file: null, 
      file_request: null,
      variables: {
        fullname: '',
        name: '',
        description: '',
        start_date: '',
        end_date: '',
        status: 'Тесты',
        status_id: 'testing',
      },
      inputs: [{
        name: 'Дата начала *', 
        placeholder: 'дд.мм.гг', 
        id: 'start_date', 
        grid: '1 / 3', 
        value: '', 
        drop: true, 
        onlyDrop: true,
        showDrop: false, 
        mask: '##.##.##',
      }, {
        name: 'Дата окончания *', 
        placeholder: 'дд.мм.гг', 
        id: 'end_date', 
        grid: '3 / 5', 
        value: '', 
        drop: true, 
        onlyDrop: true,
        showDrop: false, 
        mask: '##.##.##',
      }, {
        name: 'Состояние', 
        id: 'status', 
        grid: '5 / 7', 
        value: 'true', 
        drop: true, 
        disabled: true,
        onlyDrop: true,
        drop_data: stockStatuses,
      }, {
        name: 'Полное название *', 
        placeholder: 'Полное название', 
        id: 'fullname', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }, {
        name: 'Название для игры *', 
        placeholder: 'Название для игры', 
        id: 'name', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }, {
        name: 'Текст сообщения для игрока-участника *', 
        placeholder: 'Введите текст сообщения для игрока-участника', 
        id: 'description', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }]
    }),

    modalStockEdit: ths => ({
      title: 'Редактировать розыгрыш', 
      notification: 'Розыгрыш успешно отредактирован', 
      id: 'stock-edit', 
      request: 'api/stock', 
      method: 'put', 
      emit: 'editStock', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
        fullname: ths.modal.element.fullname,
        name: ths.modal.element.name,
        description: ths.modal.element.description,
        start_date: dateServerFilter(ths.modal.element.start_date),
        end_date: dateServerFilter(ths.modal.element.end_date),
        status: _.get(stockStatuses.find(s => [s._id, s.name].includes(ths.modal.element.status)), 'name', null),
        status_id: _.get(stockStatuses.find(s => [s._id, s.name].includes(ths.modal.element.status)), '_id', null),
      },
      inputs: [{
        name: 'Дата начала *', 
        placeholder: 'дд.мм.гг', 
        id: 'start_date', 
        grid: '1 / 3', 
        value: ths.modal.element.start_date, 
        drop: true, 
        showDrop: false, 
        mask: '##.##.##',
      }, {
        name: 'Дата окончания *', 
        placeholder: 'дд.мм.гг', 
        id: 'end_date', 
        grid: '3 / 5', 
        value: ths.modal.element.end_date, 
        drop: true, 
        showDrop: false, 
        mask: '##.##.##',
      }, {
        name: 'Состояние', 
        id: 'status', 
        grid: '5 / 7', 
        value: _.get(stockStatuses.find(s => s._id === ths.modal.element.status), 'name', null), 
        drop: true, 
        onlyDrop: true,
        drop_data: stockStatuses,
      }, {
        name: 'Полное название *', 
        placeholder: 'Полное название', 
        id: 'fullname', 
        grid: '1 / 7', 
        value: ths.modal.element.fullname, 
        drop: false,
      }, {
        name: 'Название для игры *', 
        placeholder: 'Название для игры', 
        id: 'name', 
        grid: '1 / 7', 
        value: ths.modal.element.name, 
        drop: false,
      }, {
        name: 'Текст сообщения для игрока-участника *', 
        placeholder: 'Введите текст сообщения для игрока-участника', 
        id: 'description', 
        grid: '1 / 7', 
        value: ths.modal.element.description, 
        drop: false,
      }],
    }),

    modalStockRemove: ths => ({
      title: 'Удалить розыгрыш', 
      notification: 'Розыгрыш успешно удален', 
      id: 'stock-remove', 
      request: 'api/stock', 
      message: 'этот розыгрыш', 
      method: 'delete', 
      emit: 'removeStock', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
      },
    }),
  },
};