import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/reset',
    name: 'GameReset',
    meta: {
      layout: 'gameAuth',
    },
    component: () => import('../views/game/Reset.vue'),
  },
  {
    path: '/auth',
    name: 'GameAuth',
    meta: {
      layout: 'gameAuth',
    },
    component: () => import('../views/game/Auth.vue')
  },
  {
    path: '/profile',
    name: 'GameProfile',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/Profile.vue')
  },
  {
    path: '/help',
    name: 'GameHelp',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/Help.vue')
  },
  {
    path: '/play',
    name: 'GamePlay',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/GamePlay.vue'),
  },
  {
    path: '/demo',
    name: 'GamePlayDemo',
    meta: {
      demo: true,
      layout: 'game',
    },
    component: () => import('../views/game/GamePlay.vue'),
  },
  {
    path: '/stocks',
    name: 'GameStocks',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/GameStocks.vue'),
  },
  {
    path: '/sounds',
    name: 'GameSounds',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/GameSounds.vue'),
  },
  {
    path: '/top',
    name: 'GameTop',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/Top.vue')
  },
  ,
  {
    path: '/newsfeed',
    name: 'News',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/NewsMain.vue')
  },
  {
    path: '/settings',
    name: 'GameSettings',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/GameSettings.vue')
  },
  {
    path: '/',
    name: 'Game',
    meta: {
      layout: 'game',
    },
    component: () => import('../views/game/Game.vue')
  },
  {
    path: '/admin',
    name: 'Catalog',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/admin/stocks',
    name: 'Stock',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Stock.vue')
  },
  {
    path: '/admin/newsfeed',
    name: 'Newsfeed',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Newsfeed.vue')
  },
  {
    path: '/admin/library',
    name: 'Library',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Library.vue')
  },
  {
    path: '/admin/directory',
    name: 'Directory',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Directory.vue')
  },
  {
    path: '/admin/gamers',
    name: 'User',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/User.vue')
  },
  {
    path: '/admin/settings',
    name: 'Settings',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/admin/metrics',
    name: 'Metrics',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Metrics.vue')
  },
  {
    path: '/admin/admins',
    name: 'Admin',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/admin/profile',
    name: 'Profile',
    meta: {
      layout: 'main',
    },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/admin/auth',
    name: 'Auth',
    meta: {
      layout: 'auth',
    },
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/admin/reset',
    name: 'Reset',
    meta: {
      layout: 'auth',
    },
    component: () => import('../views/Reset.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
