<template>
  <div v-if="isMegafonWithSubscriptionUser" class="subscription-status">
   <div class="subscription-status__body">
     <img 
      src="../../assets/megafon_icon-icons 2.png" 
      class="subscription-status__icon"
    />
   </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'IconSubscriptionStatus',

  computed: {
    ...mapGetters([
      'isMegafonWithSubscriptionUser',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.subscription-status {
  &__body {
    display: flex;
    align-items: center;
  }
  &__icon {
    height: 20px;
    width: 20px;
  }
}
</style>