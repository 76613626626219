export default {
  computed: {
    modalAdminAdd: ths => ({
title: "Добавить администратора", notification: 'Администратор успешно добавлен', id: 'admin-add', request: 'api/user', method: 'post', emit: 'createAdmin', file: null, file_request: null,
        variables: {
          login: '',
          email: '',
          phone: '',
          password: '',
          role: 2
        },
        inputs: [
          {name: 'Логин *', placeholder: 'Логин', id: 'login', grid: '1 / 7', value: '', drop: false},
          {name: 'E-mail *', placeholder: 'E-mail', id: 'email', grid: '1 / 7', value: '', drop: false},
          {name: 'Номер телефона', placeholder: '+7(999)-999-99-99', id: 'phone', grid: '1 / 7', value: '', drop: false, mask: '+7(###)###-##-##'},
          {name: 'Пароль *', placeholder: '************', id: 'password', grid: '1 / 7', value: '', drop: false},
        ]
    }),

    modalAdminEdit: ths => ({
title: "Редактировать администратора", notification: 'Администратор успешно отредактирован', id: 'admin-edit', request: 'api/user', method: 'put', emit: 'editAdmin', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
          login: ths.modal.element.login,
          email: ths.modal.element.email,
          phone: ths.modal.element.phone,
          role: 2
        },
        inputs: [
          {name: 'Логин *', placeholder: 'Логин', id: 'login', grid: '1 / 7', value: ths.modal.element.login, drop: false},
          {name: 'E-mail *', placeholder: 'E-mail', id: 'email', grid: '1 / 7', value: ths.modal.element.email, drop: false},
          {name: 'Номер телефона', placeholder: '+7(999)-999-99-99', id: 'phone', grid: '1 / 7', value: ths.modal.element.phone, drop: false, mask: '+7(###)###-##-##'},
        ]
    }),

    modalAdminRemove: ths => ({
title: "Удалить администратора", notification: 'Администратор успешно удален', id: 'admin-remove', request: 'api/user', message: 'этого администратора', method: 'delete', emit: 'removeAdmin', file: null, file_request: null,
        variables: {
          _id: ths.modal.element._id,
        },
    }),
  },
};