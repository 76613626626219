import stockDefault from './default';
import stockPrize from './prize';
import stockMembers from './members';
import stockWinners from './winners';

import { mapGetters } from 'vuex';

export default {
  mixins: [
    stockDefault,
    stockPrize,
    stockMembers,
    stockWinners,
  ],

  computed: {
    ...mapGetters([
      'prizesBySelectedStock',
      'selectedPrizeId',
      'selectedStockId',
      'selectedStock',
      'prizesList',
      'storageUrl',
    ]),
  },
};