import _ from 'lodash';
import Vue from 'vue';

import { categories as prizeCategories } from '@/constants/prize.js';
const MODULE_NAME = 'modalStockPrizes';

export default {
  data: () => ({
    [MODULE_NAME]: {
      freePrizesForCreate: [],
      freePrizesForEdit: [],
    },
  }),

  computed: {
    modalStockPrizesAdd: ths => ({
      title: 'Добавить приз к розыгрышу', 
      notification: 'Приз к розыгрышу успешно добавлен', 
      id: 'stock/prizes-add', 
      request: 'api/stock/prize',
      method: 'post', 
      emit: 'createStockPrize', 
      file: null, 
      file_request: null,
      variables: {
        img: null,
        name: _.get(ths[MODULE_NAME].freePrizesForCreate[0], 'name', ''),
        name_id: _.get(ths[MODULE_NAME].freePrizesForCreate[0], '_id', 'empty'),
        quantity: '1',
        category: 'Стандартная',
        category_id: 'default',
        _idStock: ths.selectedStockId,
      },
      inputs: [{
        name: 'Изображение приза', 
        id: 'img', 
        grid: '1 / 4', 
        gridH: '1 / 4', 
        value: null, 
        image: true,
      }, {
        name: 'Имя приза из списка *', 
        placeholder: 'Нет доступных призов', 
        id: 'name', 
        grid: '4 / 7', 
        value: ths[MODULE_NAME].freePrizesForCreate[0], 
        drop: true,
        onlyDrop: true,
        disabled: !ths[MODULE_NAME].freePrizesForCreate.length,
        drop_data: ths[MODULE_NAME].freePrizesForCreate,
      }, {
        name: 'Количество (не реализовано)', 
        placeholder: '', 
        id: 'quantity', 
        grid: '4 / 7', 
        value: '', 
        disabled: true,
      }, {
        name: 'Категория (не реализовано)', 
        placeholder: '', 
        id: 'category', 
        grid: '4 / 7', 
        value: '', 
        drop: true,
        onlyDrop: true,
        disabled: true,
        drop_data: prizeCategories,
      }],
    }),

    modalStockPrizesEdit: ths => ({
      title: 'Редактировать приз к розыгрышу', 
      notification: 'Приз к розыгрышу успешно отредактирован', 
      id: 'stock/prizes-edit', 
      request: 'api/stock/prize', 
      method: 'put', 
      emit: 'editStockPrize', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
        _idStock: ths.modal.element._idStock,
        img: ths.modal.element.img,
        name: ths.modal.element.name,
        quantity: ths.modal.element.quantity,
        category: ths.modal.element.category,
      },
      inputs: [{
        name: 'Изображение приза', 
        id: 'img', 
        grid: '1 / 4', 
        gridH: '1 / 4', 
        value: ths.modal.element.img, 
        image: true,
      }, {
        name: 'Имя приза из списка *', 
        placeholder: 'Имя приза', 
        id: 'name', 
        grid: '4 / 7', 
        value: ths.modal.element.name, 
        drop: true,
        onlyDrop: true,
        drop_data: ths[MODULE_NAME].freePrizesForEdit,
      }, {
        name: 'Количество (не реализовано)', 
        placeholder: '', 
        id: 'quantity', 
        grid: '4 / 7', 
        value: ths.modal.element.quantity, 
        disabled: true,
      }, {
        name: 'Категория (не реализовано)', 
        placeholder: '', 
        id: 'category', 
        grid: '4 / 7', 
        value: _.get(prizeCategories.find(s => s._id === ths.modal.element.status), 'name', null), 
        disabled: true,
        drop: true,
        onlyDrop: true,
        drop_data: prizeCategories,
      }],
    }),

    modalStockPrizesRemove: ths => ({
      title: 'Удалить приз из розыгрыша', 
      notification: 'Приз из розыгрыша успешно удален', 
      id: 'stock/prizes-remove', 
      request: 'api/stock/prize', 
      message: 'этот приз из розыгрыша', 
      method: 'delete', 
      emit: 'removeStockPrize', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
        _idStock: ths.modal.element._idStock,
      },
    }),
  },

  watch: {
    selectedPrizeId: {
      immediate: true,
      handler: MODULE_NAME + '_updateFreePrizesForSelected',
    },
  },

  methods: {
    onModalStockPrizeUpdate(variables) {
      _.each(variables, (value, variable) => {
        _.invoke(this, `${MODULE_NAME}_${variable}`, value, variable);
      });
    },

    [MODULE_NAME + '_name_id'](newValue) {
      const imageSrc = _.get(this.prizesList, newValue, {}).img || 'muzscrabble/prizes/empty.jpg';
      this.$set(this.modal_content.variables, 'img', this.storageUrl + imageSrc);
      this[MODULE_NAME + '_updateFreePrizesForSelected']();
    },

    [MODULE_NAME + '_updateFreePrizesForSelected']() {
      Vue.set(this[MODULE_NAME], 'freePrizesForEdit', []);
      Vue.set(this[MODULE_NAME], 'freePrizesForCreate', []);
      _.each(this.prizesList, prize => {
        if (prize._id === this.selectedPrizeId) this[MODULE_NAME].freePrizesForEdit.push(prize);
        if (this.prizesBySelectedStock[prize._id]) return;
        this[MODULE_NAME].freePrizesForCreate.push(prize);
        this[MODULE_NAME].freePrizesForEdit.push(prize);
      });
    },
  },
};