export default {
  computed: {
    modalPrizeAdd: ths => ({
      title: 'Добавить приз', 
      notification: 'Приз успешно добавлен', 
      id: 'prize-add', 
      request: 'api/prize', 
      method: 'post',
      emit: 'createPrize', 
      file: null, 
      file_request: null, 
      imageUpload: true,
      variables: {
        name: '',
        description: '',
        photo: '',
      },
      inputs: [{
        name: 'Полное название', 
        placeholder: 'Полное название', 
        id: 'name', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }, {
        name: 'Подробное описание (не реализовано)', 
        placeholder: 'Подробное описание', 
        id: 'description', 
        grid: '1 / 7', 
        value: '', 
        drop: false,
      }],
    }),

    modalPrizeEdit: ths => ({
      title: 'Редактировать приз', 
      notification: 'Приз успешно отредактирован', 
      id: 'prize-edit', 
      request: 'api/prize', 
      method: 'put', 
      emit: 'editPrize', 
      file: null, 
      file_request: null, 
      imageUpload: true,
      variables: {
        _id: ths.modal.element._id,
        name: ths.modal.element.name,
        description: ths.modal.element.description,
        img: ths.modal.element.img,
      },
      inputs: [{
        name: 'Полное название', 
        placeholder: 'Полное название',
        id: 'name', 
        grid: '1 / 7', 
        value: ths.modal.element.name, 
        drop: false,
        multiline: true,
      }, {
        name: 'Подробное описание (не реализовано)', 
        placeholder: 'Подробное описание', 
        id: 'description', 
        grid: '1 / 7', 
        value: ths.modal.element.description, 
        drop: false, 
        multiline: true,
      }]
    }),

    modalPrizeRemove: ths => ({
      title: 'Удалить приз', 
      notification: 'Приз успешно удалена', 
      id: 'prize-remove', 
      request: 'api/prize', 
      message: 'этот приз', 
      method: 'delete', 
      emit: 'removePrize', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
      },
    }),
  },
};