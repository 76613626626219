export const AUTH_VIEW = {
  description: 'При заходе на страницу авторизации',
  id: 'auth-open',
};

export const AUTH_CHECK_USER_EXIST = {
  description: 'Проверка существует ли пользователь с таким телефоном',
  id: 'auth-check-user-exist',
};

export const AUTH_REGISTER_USER = {
  description: 'Регистрация нового пользователя по телефону',
  id: 'auth-register-user',
};

export const AUTH_OTP_SUCCESS = {
  description: 'Подтверждение номера на странице авторизации',
  id: 'auth-otp-success',
};

export const AUTH_OTP_ERROR = {
  description: 'Ошибка при подтверждении номера на странице авторизации',
  id: 'auth-otp-error',
};

export const AUTH_TOKEN_SUCCESS = {
  description: 'Вход по токенцу на странице авторизации',
  id: 'auth-token-success',
};

export const AUTH_TOKEN_ERROR = {
  description: 'Ошибка при входе по токену на странице авторизации',
  id: 'auth-token-error',
};