<template>

  <div class="hotnews">
    <div class="hotnews__body">
      <router-link to="/newsfeed" target="_blank" class="hotnewss__link">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="hotnews__icon">
          <path d="M24 9.49987C24 8.29087 23.141 7.28187 22 7.04987V0.999875C22 0.478875 21.601 0.0458746 21.083 0.00287463C20.569 -0.0451254 20.1 0.321875 20.014 0.834875C19.625 3.16787 16.394 3.99987 13.5 3.99987H5.5C2.467 3.99987 0 6.46687 0 9.49987C0 11.1509 0.732 12.6349 1.888 13.6439L5.03 22.0269C5.304 22.7789 5.856 23.3809 6.582 23.7189C6.985 23.9069 7.415 24.0009 7.846 24.0009C8.193 24.0009 8.54 23.9399 8.876 23.8179C9.628 23.5439 10.23 22.9919 10.568 22.2659C10.906 21.5389 10.941 20.7249 10.667 19.9739L8.864 14.9999H13.5C16.394 14.9999 19.625 15.8309 20.014 18.1649C20.095 18.6499 20.515 18.9999 20.999 18.9999C21.027 18.9999 21.055 18.9999 21.082 18.9969C21.601 18.9539 21.999 18.5209 21.999 17.9999V11.9499C23.14 11.7179 24 10.7099 24 9.49987ZM5.5 5.99987H6.996L6.966 12.9999H5.501C3.571 12.9999 2.001 11.4299 2.001 9.49987C2.001 7.56987 3.57 5.99987 5.5 5.99987ZM8.788 20.6569C8.879 20.9069 8.868 21.1789 8.755 21.4209C8.642 21.6629 8.442 21.8469 8.191 21.9389C7.941 22.0309 7.669 22.0189 7.427 21.9059C7.185 21.7929 7.001 21.5929 6.906 21.3339L4.498 14.9089C4.823 14.9689 5.158 15.0009 5.5 15.0009H6.737L8.788 20.6579V20.6569ZM20 14.6989C18.513 13.6039 16.28 12.9999 13.5 12.9999H8.965L8.995 5.99987H13.499C16.279 5.99987 18.512 5.39587 19.999 4.30087V14.6979L20 14.6989Z" fill="white"/>
        </svg>
        <span class="hotnews__text">Свежие новости</span>
      </router-link>
    </div>

  </div>

</template>

<script>
export default {
  name: "IconHotNews"
}
</script>

<style scoped>
.hotnews {

}

.hotnews__body {
}

.hotnewss__link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hotnews__icon {
  height: 20px;
  width: 20px;
}

.hotnews__text {
  padding-left: 5px;
  color: #fff;

}
</style>