const allowIconEvents = ['click', 'mouseover', 'mouseout'];

export default {
  props: {
    color: {
      type: String,
      default: () => '#fff',
    },
    speed: {
      type: [Number, String],
      default: () => 1,
    },
    size: {
      type: [Number, String],
    },
    stroke: {
      type: [Number, String],
      default: () => 2,
    },
    width: {
      type: [Number, String],
      default: () => 512,
    },
    height: {
      type: [Number, String],
      default: () => 512,
    },
  },

  data: () => ({
    localColor: '#000',
  }),

  computed: {
    iconWidth: ths => ths.size || ths.width,
    iconHeight: ths => ths.size || ths.height,
    events() {
      return allowIconEvents.reduce((acc, eventName) => {
        acc[eventName] = this.$emit.bind(this, eventName);
        return acc;
      }, {});
    },
  },

  watch: {
    color: {
      immediate: true,
      handler(color) {
        this.localColor = color;
      },
    },
  },
};