import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";

const store = {};

store.state = () => ({
  settingsLoaded: false,
  sdk: null,
  instruction: { video: null },
  emptyStock: {},
  ratings: {},
  maxHelp: 0,
  isRepairWorks: null,
  repairLayourText: '',
  holidays: {},
});

store.getters = {
  maxHelp: state => state.maxHelp,
  emptyStock: state => state.emptyStock,
  settingsLoaded: state => state.settingsLoaded,
  ratings: state => _.pickBy(state.ratings, 'show'),
  repairLayourText: state => state.repairLayourText,
  isRepairWorks: state => state.isRepairWorks,
  instructionVideo(state) {
    try {
      const url = new URL(state.storageUrl);
      url.pathname = state.instruction.video;
      return url.toString();
    } catch(err) {
      return null;
    };
  },
  headers(_1, _2, rootState) {
    return { token: rootState.token };
  },
  axiosOptions(_, { headers }) {
    return { headers };
  },
  holidaysExtended(state) {
    return Object.entries(state.holidays).reduce((acc, [id, holiday]) => {
      return acc.concat({ id, ...holiday });
    }, []);
  },
  currentHoliday(state, getters) {
    return getters.holidaysExtended.find(holiday => {
      const validTimeStart = new Date(holiday.time_start).valueOf() <= Date.now();
      const validTimeEnd = new Date(holiday.time_end).valueOf() >= Date.now();
      return validTimeStart && validTimeEnd;
    }) || {};
  },
};

store.actions = {
  getInstructionVideo({ commit, getters }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`/api/help/video`, getters.axiosOptions);
        commit('setInstructionVideo', response.data);
        resolve(response.data);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
  getAppSettings({ commit, getters }) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`/api/appsettings`, getters.axiosOptions);
        commit('setAppSettings', response.data);
        resolve(response.data);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },
};

store.mutations = {
  registerSDK(state, sdk) {
    state.sdk = sdk;
  },
  setInstructionVideo(state, video) {
    Vue.set(state.instruction, 'video', video);
  },
  setAppSettings(state, settings) {
    state.settingsLoaded = true;
    _.each(settings, (setting, key) => {
      Vue.set(state, key, setting);
    });
  },
};

export default store;