import zeroPadFilter from './zeroPad.filter';

export default function dateFilter(value, format = 'date', year = true) {
  let date = new Date(value);
  let options = '';
  if (format.includes('date')) {
    options += `${zeroPadFilter(date.getDate(), 2)}.${zeroPadFilter(date.getMonth() + 1, 2)}`;
    if (year) {
      options += `.${String(date.getFullYear()).substring(2)}`;
    }
  }
  if (format.includes('time')) {
    options += ` ${zeroPadFilter(date.getHours(), 2)}:${zeroPadFilter(date.getMinutes(), 2)}`;
  }
  return options;
}