import Vue from 'vue';
import _ from 'lodash';
import axios from "axios";
import router from '@/router/index';

const getUsersSelectDefault = {
  'email': true,
  'phone': true,
  'login': true,
  'type': true,
  'score': true,
  'stocks': true,
  '-token': false, 
  '-tokenGame': false, 
  '-resetPasswordToken': false, 
  '-resetPasswordPhone': false, 
  '-password': false,
};

const store = {};

store.state = () => ({
  users: {},
  selectedUserId: null,
  category: 'guest',
  role: 0,
});

store.getters = {
  usersList: state => state.users,
  isDemoUser: state => state.role === 4,
  isDemoCompleted(state, getters, rootState, rootGetters) {
    if (!getters.isDemoUser) return true;
    if (!rootState.game.token) return false;
    const maxSounds = _.size(rootGetters.sounds);
    const guessedSounds = _.size(rootState.game.historyGuessedSounds);
    return guessedSounds >= maxSounds;
  },
  selectedUserId: state => state.selectedUserId,
  usersListWithUser(state, _, rootState) {
    return {
      [rootState.user._id]: rootState.user,
      ...state.users,
    };
  },
};

store.actions = {
  getUsers({ rootState, commit }, { find, select, filter, ids, ignoreExisting = false, limit, params = {} }) {
    return new Promise(async (resolve, reject) => {
      try {
        if (Array.isArray(ids) && !ids.length) return resolve({});
        const options = { headers: {}, params };
        options.headers.token = rootState.token;
        options.params.find = find;
        options.params.limit = limit;
        options.params.filter = filter;
        options.params.ids = ignoreExisting ? _.values(_.omit(ids, _.keys(rootState.users))) : ids;
        options.params.select = select || getUsersSelectDefault;
        const response = await axios.get('/api/gamers', options);
        const users = response.data.reduce((acc, user) => ({ ...acc, [user._id]: user }), {});
        commit('addUsers', users);
        resolve(users);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },

  editMemberFromStock({ rootState, commit }, { stockId, userId, score, prize }) {
    return new Promise(async (resolve, reject) => {
      try {
        const user = _.cloneDeep(rootState.user.users[userId]);
        user.stocks[stockId] = { score, prize };
        commit('updateUser', user);
        resolve(user);
      } catch(err) {
        console.error(err);
        reject(err);
      };
    });
  },

  logout({ rootGetters, commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `/api/auth/logout`;
        const options = rootGetters.axiosOptions;
        const response = await axios.post(url, {}, options);
        resolve(response);
      } catch(err) {
        console.error(err);
        reject(err);
      } finally {
        commit('clearUserModule');
        commit('clearToken');
        router.push('/auth');
      };
    });
  },
};

store.mutations = {
  clearUserModule(state) {
    for (const key in state)
      Vue.delete(state, key);
    for (const key in store.state())
      Vue.set(state, key, store.state()[key]);
  },
  setUserRole(state, role) {
    state.role = role;
  },
  addUsers(state, newUsers) {
    Vue.set(state, 'users', {
      ...state.users,
      ...newUsers,
    });
  },
  addUser(state, newUser = {}) {
    if (!newUser._id) return;
    Vue.set(state.users, newUser._id, newUser);
  },
  updateUser(state, user = {}) {
    if (!state.users[user._id]) return;
    Vue.set(state.users, user._id, user);
  },
  // removeUser(state, id) {
  //   if (!state.users[id]) return;
  //   Vue.delete(state.users, id);
  // },
  setSelectedUserId(state, newUserId) {
    Vue.set(state, 'selectedUserId', newUserId);
  },
};

export default store;