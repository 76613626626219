import _ from 'lodash';
import Vue from 'vue';

const MODULE_NAME = 'modalStockMembers';
const emptyPrize = { id: null, name: 'Без приза' };

export default {
  data: () => ({
    [MODULE_NAME]: {
      freePrizesForEdit: [emptyPrize],
    },
  }),

	computed: {
    // modalStockMembersAdd: ths => ({}),
    // modalStockMembersRemove: ths => ({}),

    modalStockMembersEdit: ths => ({
      title: 'Редактировать игрока розыгрыша', 
      notification: 'Игрок розыгрыша успешно отредактирован', 
      id: 'stock/members-edit', 
      request: 'api/stock/member', 
      method: 'put', 
      emit: 'editStockMember', 
      file: null, 
      file_request: null,
      variables: {
        _id: ths.modal.element._id,
        _idStock: ths.modal.element._idStock,
        login: ths.modal.element.login,
        email: ths.modal.element.email,
        type: ths.modal.element.type,
        score: ths.modal.element.score,
        prize: ths.modal.element.prize,
        prize_id: ths.modal.element.prize_id,
        category: ths.modal.element.category,
      },
      inputs: [{
        name: 'Логин', 
        id: 'login', 
        grid: '1 / 4', 
        value: ths.modal.element.login, 
        disabled: true,
      }, {
        name: 'Почта', 
        id: 'email', 
        grid: '4 / 7', 
        value: ths.modal.element.email, 
        disabled: true,
      }, {
        name: 'Изображение приза', 
        id: 'img', 
        grid: '1 / 4', 
        gridH: '2 / 6', 
        value: ths.modal.element.img, 
        image: true,
      }, {
        name: 'Подписка', 
        id: 'type', 
        grid: '4 / 7', 
        value: ths.modal.element.type, 
        disabled: true,
      }, {
        name: 'Приз', 
        id: 'prize', 
        grid: '4 / 7', 
        placeholder: 'Приз не выбран',
        value: ths.modal.element.prize,  
        drop: true,
        onlyDrop: true,
        drop_data: ths[MODULE_NAME].freePrizesForEdit,
      }, {
        name: 'Категория', 
        placeholder: 'Приз не выбран',
        id: 'category', 
        grid: '4 / 7', 
        value: ths.modal.element.category,
        disabled: true,
      }, {
        name: 'Баллы', 
        id: 'score', 
        grid: '4 / 7', 
        value: ths.modal.element.score, 
      }],
    }),
	},

  watch: {
    selectedStockId: {
      immediate: true,
      handler: MODULE_NAME + '_updateFreePrizesForSelected',
    },
  },

  methods: {
    onModalStockMemberUpdate(variables) {
      _.each(variables, (value, variable) => {
        _.invoke(this, `${MODULE_NAME}_${variable}`, value, variable);
      });
    },

    [MODULE_NAME + '_score'](newValue, key) {
      this.$set(this.modal_content.variables, key, parseInt(newValue) || 0);
    },

    [MODULE_NAME + '_prize_id'](newValue) {
      const imageSrc = _.get(this.prizesList, newValue, {}).img || 'muzscrabble/prizes/empty.jpg';
      this.$set(this.modal_content.variables, 'img', this.storageUrl + imageSrc);
      this[MODULE_NAME + '_updateFreePrizesForSelected']();
    },

    [MODULE_NAME + '_updateFreePrizesForSelected']() {
      Vue.set(this[MODULE_NAME], 'freePrizesForEdit', [emptyPrize]);
      _.each(this.prizesBySelectedStock, prize => {
        const prizeOnStock = _.get(this.selectedStock.prizes, prize._id, {});
        prize.quantity = prizeOnStock.quantity || 1;
        prize.category = prizeOnStock.category;
        const times = prize.quantity;
        _.times(times, index => {
          const length = this[MODULE_NAME].freePrizesForEdit.length;
          this.$set(this[MODULE_NAME].freePrizesForEdit, length, {
            id: prize._id,
            ...prize,
          });
        });
      });
    },
  },
};